<template>
  <Dialog v-model:visible="isModalVisible" modal block-scroll :header="$t('club.honors.edit')" :style="{width: '30rem'}" @hide="isSubmitted = false">
    <div class="form-row mb-3">
      <label for="memberName" class="form-label">{{ $t('profile.fullName') }}</label>
      <span class="w-100">{{ selectedMemberHonor.memberFirstName }} {{ selectedMemberHonor.memberLastName }}</span>
    </div>
    <div class="form-row mb-3">
      <label for="memberHonor" class="form-label">{{ $t('club.member.honors.honor') }}</label>
      <span id="memberHonor" class="w-100">{{ selectedMemberHonor.description }}</span>
    </div>
    <div class="form-row mb-3">
      <label for="honorDate" class="form-label">{{ $t('date') }}</label>
      <DatePicker id="honorDate" v-model="selectedMemberHonor.dateOfHonor"
                  :model-value="formatDate(selectedMemberHonor.dateOfHonor)"
                  show-icon class="w-100"
                  @update:model-value="analyzeData"/>
    </div>
    <div class="form-row mb-3">
      <label for="honorComment" class="form-label">{{ $t('comment') }}</label>
      <Textarea id="honorComment" v-model="selectedMemberHonor.comment" rows="3" class="w-100" @update:model-value="analyzeData"/>
    </div>

    <div class="d-flex justify-content-between">
      <Button :title="$t('save')" :label="$t('save')"
              unstyled class="btn btn-outline-primary"
              :disabled="isSubmitted"
              @click="submitMemberHonorData"/>
      <Button :title="$t('cancel')" :label="$t('cancel')"
              :disabled="isSubmitted"
              unstyled class="btn btn-outline-secondary"
              @click="$emit('closeModal')"/>
    </div>
  </Dialog>
</template>

<script setup>
import DatePicker from "primevue/datepicker";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Textarea from "primevue/textarea";
import {computed, ref} from "vue";
import moment from "moment/moment";
import Message from "@/helpers/message";
import {useHonorsStore} from "@/store/modules/honors/honors";
import {useI18n} from "vue-i18n";
import {useMessagesStore} from "@/store/modules/messages/messages";

const honorsStore = useHonorsStore();
const messageStore = useMessagesStore();
const {t} = useI18n();

const showSuccess = ref(false);
const showFailure = ref(false);

const props = defineProps({
  memberHonor: {
    type: Object,
    required: true,
  },
  modalVisible: {
    type: Boolean,
    required: true,
  }
});

const isModalVisible = computed({
  get: () => {
    return props.modalVisible;
  },
  set: () => {
    emit('closeModal');
  },
});

const emit = defineEmits([ 'saved', 'closeModal' ]);

const isSubmitted = ref(false);

const selectedMemberHonor = computed({
  get: () => {
    return JSON.parse(JSON.stringify(props.memberHonor));
  },
  set: (value) => {
    return value;
  },
});

const memberHonorValid = ref({
  dateOfHonor: false,
  comment: false,
});

const submitMemberHonorData = () => {
  isSubmitted.value = true;
  analyzeData();
  if (allTrue(memberHonorValid.value)) {
    const payload = {
      No: selectedMemberHonor.value.contactNo,
      Line_No: selectedMemberHonor.value.lineNo,
      Section: selectedMemberHonor.value.clubNo,
      Honor: selectedMemberHonor.value.honorId,
      Date: selectedMemberHonor.value.dateOfHonor,
      Comment: selectedMemberHonor.value.comment,
    };
    honorsStore.saveOrUpdateMemberHonor(payload).then(response => {
      if (response.status >= 200 && response.status < 300) {
        showSuccess.value = true;
        messageStore.addInfo(new Message('info', true, false, t('club.honors.edit'), '', t('club.honors.save.success'), false, 'COMPONENT'));
      } else {
        showFailure.value = true;
        messageStore.addError(new Message('error', true, false, t('club.honors.edit'), '', t(response.data), false, 'COMPONENT'));
      }
    }).catch(e => {
      showFailure.value = true;
      messageStore.addError(new Message('error', true, false, t('club.honors.edit'), '', e, false, 'COMPONENT'));
    }).finally(() => {
      emit('saved');
    });
  }
};

const analyzeData = () => {
  checkProperties(selectedMemberHonor);
};

const checkProperties = (obj) => {
  for (const key in obj.value) {
    if (key === 'comment') {
      memberHonorValid.value[key] = isEmptyOrWithinMaxLength(obj.value[key], 250);
    } else if (key === 'dateOfHonor') {
      memberHonorValid.value[key] = (!obj.value[key]) || dateValid(obj.value[key]);
    }
  }
};

const isEmptyOrWithinMaxLength = (value, maxLength) => {
  return value !== undefined && value !== null && (value.trim() === '' || value.trim().length <= maxLength);
};

const dateValid = (value) => {
  const dateValue = moment(String(value));
  return dateValue.isValid();
};

const allTrue = (obj) => {
  for (const o in obj) {
    if (!obj[o]) return false;
  }
  return true;
};

// eslint-disable-next-line
const formatDate = (value, format) => {
  if (value) {
    const dateValue = moment(String(value));
    if (dateValue?.isAfter('1900-01-01')) {
      return dateValue.format(format || 'DD.MM.YYYY');
    }
  }
  return '-';
};

</script>
