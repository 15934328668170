<template>
  <association-layout :title="associationData.status === 200 ? $t('association.profile') + ' ' + associationData?.data?.Name : $t('association.notFound.title')" :is-loading="false">
    <template #content v-if="associationData.status === 200">

      <div class="row">
        <div class="col">
          <h5 class="mb-3">
            {{ $t('association.club.members.title', {clubName: currentClubData?.data?.Name}) }}
          </h5>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <DataTable :value="clubMembers"
                     :sortField="sortColumn" :sortOrder="1" @sort="onSort"
                     v-model:selection="selection"
                     dataKey="memberId"
                     v-model:filters="filters" filterDisplay="row"
                     :loading="isLoading"
                     ref="dt"
                     paginator :rows="50" :rowsPerPageOptions="[20, 50, 100, 150]"
                     paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
                     :currentPageReportTemplate="`{first}` + ' ' + $t('section.clubMembers.pagination.to') + ' ' + `{last}` + ' ' + $t('section.clubMembers.pagination.of') + ' ' +  `{totalRecords}`"
                     csvSeparator=";" :exportFilename="$t('section.clubMembers.exportFilename')" :exportFunction="exportFunction"
                     stripedRows>

            <template #empty>{{ $t('section.clubMembers.noMembersFound') }}</template>
            <template #paginatorfirstpagelinkicon>
              <i class="bi bi-arrow-bar-left"/>
            </template>
            <template #paginatorprevpagelinkicon>
              <i class="bi bi-arrow-left"/>
            </template>
            <template #paginatornextpagelinkicon>
              <i class="bi bi-arrow-right"/>
            </template>
            <template #paginatorlastpagelinkicon>
              <i class="bi bi-arrow-bar-right"/>
            </template>

            <Column selectionMode="multiple" class="dt-col-2"></Column>

            <Column v-for="col in columns" :key="col.field" :field="col.field"
                    :filter-field="col.field" filter-header-class="table-header-filter" :show-filter-menu="false"
                    :header="col.header"
                    :sortable="col.sortable" :header-class="(sortColumn === col.field ? 'tableHeader text-primary' : 'tableHeader')"
                    :exportable="col.exportable"
                    :hidden="!col.visible">
              <template #body="{data}">
                <router-link :to="{name: ASSOC_EDIT_MEMBER.name, query: {memberId: data.memberId, associationId: currentAssociationId, clubId: currentClubId}}" class="table-link" @click="isLoading = true" @keyup.enter="isLoading = true" @keyup.space="isLoading = true">
                  <span v-if="col.type === 'Status'">
                    <i class="bi" :class="[getStatusIcon(data[col.field].toLowerCase()), 'text-' + data[col.field].toLowerCase()]"
                       data-bs-toggle="tooltip" :data-bs-original-title="$t('section.clubMembers.status.' + data[col.field])"
                       :aria-label="$t('section.clubMembers.status.' + data[col.field])" :title="$t('section.clubMembers.status.' + data[col.field])"></i>
                  </span>
                  <span v-else>
                    {{ formatEntry(col.type, data[col.field]) }}
                  </span>
                </router-link>
              </template>
              <template #filter="{ filterModel, filterCallback }" v-if="col.filterable">
                <Select v-model="filterModel.value" @change="filterCallback()" :options="possibleLanguages" :placeholder="$t('search.filter')" :showClear="true" v-if="col.type === 'Language'">
                  <template #value="slotProps">
                    <div v-if="slotProps.value" class="flex items-center">
                      <div>{{ $t('languages.' + slotProps.value) }}</div>
                    </div>
                    <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                  </template>
                  <template #option="slotProps">
                    {{ $t('languages.' + slotProps.option) }}
                  </template>
                </Select>
                <Select v-model="filterModel.value" @change="filterCallback()" :options="possibleSalutations" :placeholder="$t('search.filter')" :showClear="true" v-else-if="col.type === 'Salutation'">
                  <template #value="slotProps">
                    <div v-if="slotProps.value" class="flex items-center">
                      <div>{{ $t('club.listMember.salutation' + slotProps.value) }}</div>
                    </div>
                    <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                  </template>
                  <template #option="slotProps">
                    {{ $t('club.listMember.salutation' + slotProps.option) }}
                  </template>
                </Select>
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('table.filter.header', {filter: col.header})" class="form-control input-filter" v-else/>
              </template>
            </Column>

          </DataTable>

          <div class="row w-100 flex-center gap-2 mb-3 mt-3">
            <router-link :to="{name: ASSOC_SHOW_CLUBS.name, query: {associationId: currentAssociationId}}" class="btn btn-outline-secondary width-auto" @click="isLoading = true">
              <i class="bi bi-arrow-left me-2"></i> {{ $t('back') }}
            </router-link>
            <Button :title="$t('export')" :label="$t('export')" @click="exportCSV($event)" unstyled class="btn btn-outline-primary width-auto">
              <i class="bi bi-filetype-csv"></i>
            </Button>
            <Button :title="$t('refresh')" label=" " unstyled class="btn btn-outline-secondary width-auto" icon="bi bi-arrow-clockwise" @click="confirmReload($event, currentAssociationId, currentClubId)"/>
          </div>
        </div>
      </div>
      <LoaderOverlay v-if="isLoading"/>

      <div class="row">
        <FaqPanel class="m-0 border-0"/>
      </div>
    </template>
    <template #content v-else>
      <NoClubFoundAlert/>
    </template>
  </association-layout>
  <ConfirmDialog style="width: 25rem;" :pt="{footer: {class: 'd-flex justify-content-between flex-row-reverse pt-3'}, content: {class: 'd-flex flex-column border-bottom'}, icon: {class: 'w-auto h-auto flex-shrink-1', style: 'font-size: 3.5rem; line-height: 0'}}"/>
  <BootstrapToast/>
</template>

<script setup>
import AssociationLayout from "@/components/association/AssociationLayout.vue";
import {computed, onMounted, onUnmounted, ref} from 'vue'
import {useRoute} from 'vue-router'
import {FilterMatchMode} from "@primevue/core/api"
import {useClubStore} from "@/store/modules/club/club.js";
import {useI18n} from "vue-i18n";

import FaqPanel from "@/components/ui/FaqPanel.vue";
import LoaderOverlay from "@/components/ui/LoaderOverlay.vue";
import Button from 'primevue/button';
import Select from 'primevue/select';
import ConfirmDialog from "primevue/confirmdialog";
import {useConfirm} from "primevue/useconfirm";

import moment from "moment/moment";
import BootstrapToast from "@/components/ui/BootstrapToast.vue";
import NoClubFoundAlert from "@/components/ui/alert/NoClubFoundAlert.vue";
import {ASSOC_EDIT_MEMBER, ASSOC_SHOW_CLUBS} from "@/router/urls_association";
import {useAssociationStore} from "@/store/modules/association/association";

const dt = ref();
const route = useRoute()
const clubStore = useClubStore();
const associationStore = useAssociationStore();
const {t} = useI18n()
const i18nLocale = useI18n().locale.value;
const confirm = useConfirm();

const associationData = computed(() => clubStore.getClubData);
const currentClubData = computed(() => associationStore.getCurrentClubData);

const columns = [
  {field: 'memberId', header: t('club.listMember.nr'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'salutation', header: t('club.listMember.salutation'), filterable: true, sortable: true, exportable: true, visible: true, type: 'Salutation'},
  {field: 'surname', header: t('club.listMember.surname'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'addition', header: t('club.member.addressAddition'), filterable: true, sortable: true, exportable: true, visible: false, type: 'String'},
  {field: 'addition2', header: t('club.member.addressAddition2'), filterable: true, sortable: true, exportable: true, visible: false, type: 'String'},
  {field: 'firstName', header: t('club.listMember.firstName'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'address', header: t('club.listMember.address'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'postCode', header: t('club.listMember.zipCode'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'city', header: t('club.listMember.place'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'birthday', header: t('club.listMember.birthday'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'emailPrivate', header: t('club.listMember.eMailPrivate'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'emailAlternative', header: t('club.listMember.eMailAlternative'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'emailVerein', header: t('club.listMember.eMailVerein'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'emailVerband', header: t('club.listMember.eMailVerband'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'phonePrivate', header: t('club.listMember.phonePrivate'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'phoneBusiness', header: t('club.listMember.phoneBusiness'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'phoneMobile', header: t('club.listMember.phoneMobile'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'membershipCategories', header: t('club.listMember.membershipCategory'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'membershipAdditions', header: t('club.listMember.membershipCategoryAddition'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'squads', header: t('club.listMember.squads'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'language', header: t('club.listMember.language'), filterable: true, sortable: true, exportable: true, visible: true, type: 'Language'},
  {field: 'socialInsuranceNr', header: t('club.listMember.socialInsuranceNr'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'swissAthleticsNr', header: t('club.listMember.swissAthleticsNr'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'statusMySTV', header: t('club.listMember.statusMySTV'), filterable: false, sortable: true, exportable: true, visible: true, type: 'Status'},
  {field: 'releaseMySTV', header: t('club.listMember.releaseMySTV'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'typeOfSportLSA', header: t('club.listMember.lsaTypeOfSport'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'yearValidLSA', header: t('club.listMember.lsaValidYear'), filterable: true, sortable: true, exportable: true, visible: true, type: 'Date'},
  {field: 'mySTVComment', header: t('club.listMember.mySTVComment'), filterable: true, sortable: true, exportable: true, visible: false, type: 'String'},
  {field: 'gymLiveAbo', header: t('club.member.subscriptions.gymLive'), filterable: true, sortable: true, exportable: true, visible: false, type: 'Boolean'},
  {field: 'regionalAbo', header: t('club.member.subscriptions.regional'), filterable: true, sortable: true, exportable: true, visible: false, type: 'Boolean'},
  {field: 'addressRelease', header: t('club.member.subscriptions.addressRelease'), filterable: true, sortable: true, exportable: true, visible: false, type: 'Boolean'},
  {field: 'advertising', header: t('club.member.subscriptions.advertising'), filterable: true, sortable: true, exportable: true, visible: false, type: 'Boolean'},
  {field: 'regionalAbo', header: t('club.member.subscriptions.regional'), filterable: true, sortable: true, exportable: true, visible: false, type: 'Boolean'},
  {field: 'regionalAboNo', header: t('club.member.subscriptions.regionalNo'), filterable: true, sortable: true, exportable: true, visible: false, type: 'String'},

];

const filters = ref({
  memberId: {value: null, matchMode: FilterMatchMode.CONTAINS},
  surname: {value: null, matchMode: FilterMatchMode.CONTAINS},
  firstName: {value: null, matchMode: FilterMatchMode.CONTAINS},
  socialInsuranceNr: {value: null, matchMode: FilterMatchMode.CONTAINS},
  swissAthleticsNr: {value: null, matchMode: FilterMatchMode.CONTAINS},
  salutation: {value: null, matchMode: FilterMatchMode.CONTAINS},
  language: {value: null, matchMode: FilterMatchMode.CONTAINS},
  address: {value: null, matchMode: FilterMatchMode.CONTAINS},
  postCode: {value: null, matchMode: FilterMatchMode.CONTAINS},
  city: {value: null, matchMode: FilterMatchMode.CONTAINS},
  birthday: {value: null, matchMode: FilterMatchMode.CONTAINS},
  emailPrivate: {value: null, matchMode: FilterMatchMode.CONTAINS},
  emailAlternative: {value: null, matchMode: FilterMatchMode.CONTAINS},
  emailVerein: {value: null, matchMode: FilterMatchMode.CONTAINS},
  emailVerband: {value: null, matchMode: FilterMatchMode.CONTAINS},
  phonePrivate: {value: null, matchMode: FilterMatchMode.CONTAINS},
  phoneBusiness: {value: null, matchMode: FilterMatchMode.CONTAINS},
  phoneMobile: {value: null, matchMode: FilterMatchMode.CONTAINS},
  membershipCategories: {value: null, matchMode: FilterMatchMode.CONTAINS},
  membershipAdditions: {value: null, matchMode: FilterMatchMode.CONTAINS},
  squads: {value: null, matchMode: FilterMatchMode.CONTAINS},
  statusMySTV: {value: null, matchMode: FilterMatchMode.CONTAINS},
  releaseMySTV: {value: null, matchMode: FilterMatchMode.CONTAINS},
  typeOfSportLSA: {value: null, matchMode: FilterMatchMode.CONTAINS},
  yearValidLSA: {value: null, matchMode: FilterMatchMode.CONTAINS},
});
const isSelectable = ref(false);
const isLoading = ref(false);
const sortColumn = ref('memberId');
const selection = ref([]);
const possibleSalutations = ref([ "MS", "MR" ]);
const possibleLanguages = ref([ "de", "fr", "it", "en" ])

const clubMembers = computed(() => {
  return associationStore.getClubMembers;
})

const currentAssociationId = computed(() => {
  return associationData.value?.data?.No;
})

const currentClubId = computed(() => {
  return currentClubData.value?.data?.No;
});

const getStatusIcon = (memberStatus) => {
  switch (memberStatus) {
    case 'initiated':
    case 'offboard':
    case 'pending':
    case 'reminded':
      return 'bi-circle-fill';
    case 'email_missing':
    case 'email_duplicate':
      return 'bi-exclamation-triangle-fill';
    case 'quarantine':
      return 'bi-wrench-adjustable-circle-fill';
    default:
      return 'bi-circle-fill';
  }
}

const onSort = (event) => {
  sortColumn.value = event.sortField;
}

const formatEntry = (type, value) => {
  switch (type) {
    case 'Date':
      return formatDate(value);
    case 'Array':
      return [ ...new Set(Array.prototype.map.call(value, s => s.description)) ].join(', ');
    case 'Salutation':
      if (value === 'MS') {
        return t('club.listMember.salutationMS');
      } else if (value === 'MR') {
        return t('club.listMember.salutationMR');
      }
      return value;
    case 'Language':
      if (value === 'de' || value === 'DE') {
        return t('languages.de');
      } else if (value === 'fr' || value === 'FR') {
        return t('languages.fr');
      }
      return value;
    case 'Boolean':
      if (value) {
        return t('yes');
      } else {
        return t('no');
      }
    default:
      return value;
  }
}

const confirmReload = (event, associationId, clubId) => {
  confirm.require({
    target: event.currentTarget,
    header: t('warning'),
    message: t('association.club.members.reload.warning'),
    icon: 'bi bi-exclamation-triangle',
    acceptProps: {
      label: t('ok'),
      unstyled: true,
      class: 'btn btn-outline-primary',
    },
    rejectProps: {
      label: t('cancel'),
      unstyled: true,
      class: 'btn btn-outline-secondary',
    },
    accept: () => {
      forceReloadClubMembers(associationId, clubId)
    },
  });
}

const forceReloadClubMembers = async (associationId, clubId) => {
  isLoading.value = true;
  associationStore.loadClubListMembers(associationId, clubId, true, true, true, i18nLocale).then(() => {
    isLoading.value = false;
  });
};

const formatDate = (value, format) => {
  if (value) {
    const dateValue = moment(String(value));
    if (dateValue && dateValue.isAfter('1900-01-01')) {
      return dateValue.format(format || 'DD.MM.YYYY');
    }
  }
  return '–';
}
const exportCSV = () => {
  dt.value.exportCSV({selectionOnly: selection.value.length > 0});
}

const exportFunction = (entry) => {
  switch (entry.field) {
    case 'salutation':
      return formatEntry('Salutation', entry.data);
    case 'language':
      return formatEntry('Language', entry.data);
    case 'statusMySTV':
      return t('section.clubMembers.status.' + entry.data);
    case 'membershipCategories':
    case 'membershipAdditions':
      if (String(entry.data).endsWith(', ')) {
        return String(entry.data).slice(0, -2); //entfernt trailing ', '
      }
      return String(entry.data);
    case 'advertising':
    case 'regionalAbo':
    case 'gymLiveAbo':
    case 'addressRelease':
      return formatEntry('Boolean', entry.data);
    default:
      return String(entry.data)
  }
}

onMounted(() => {
  isSelectable.value = selection.value.length > 0;
  isLoading.value = true;
  associationStore.loadClubData(route.query.associationId, route.query.clubId).then(() => {
    associationStore.loadClubListMembers(route.query.associationId, route.query.clubId, false, true, true, i18nLocale).then(() => {
      isLoading.value = false;
    });
  });
})

onUnmounted(() => {
  isLoading.value = false;
})
</script>

<style scoped lang="scss">
.table-link {
  display: inline-block;
  width: 100%;
}
</style>
