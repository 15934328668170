export const ROOT_PAGE = "/";
export const LOGIN_PAGE = "/login";
export const START_PAGE = "/start";
export const MEMBERCARD_PAGE = "/membercard";
export const USER_PROFILE = '/profile';
export const USER_PROFILE_CHANGE_ADDRESS = '/change-address';
export const USER_PROFILE_CHANGE_PASSWORD = '/change-password';
export const USER_PROFILE_CHANGE_EMAIL = '/change-email';
export const USER_PROFILE_CONFIRM_EMAIL = '/confirm-email';
export const USER_PROFILE_CHANGE_BIRTHDAY = '/change-birthday';
export const USER_PROFILE_CHANGE_SPORTS = '/change-sports';
export const USER_PROFILE_CHANGE_2FA = '/change-two-factor-settings';
export const FORGOT_PASSWORD = '/forgot-password';
export const PASSWORD_RESET = "/password-reset";
export const ONBOARDING = '/password-initial';
export const CREATE_ACCOUNT = '/create-account';
export const CLUB_MEMBERS_PAGE = '/club_members';
export const SINGLE_MEMBER_PAGE = '/member';
export const FORM_WIZARD = '/wizard';
export const TOTP = '/totp';
export const ADD_2FA_DEVICE = '/add-device'
export const MEMBER_OFFERS = '/member-offers';

export const CLUB_EDIT_PROFILE = "/club/editProfile"
export const CLUB_SHOW_FIGURES = "/club/figures";
export const CLUB_SHOW_FUNCTIONS_AND_ACTIVITIES = "/club/functions-activities";
export const CLUB_SHOW_GROUPS = "/club/groups";
export const CLUB_SHOW_GROUP_MEMBERS = "/club/group-members";
export const CLUB_SHOW_GROUP_FUNCTIONS = "/club/group-functions";

export const CLUB_SHOW_GUARDIANS = '/club/guardians';

export const CLUB_SHOW_HONORS = {path: "/club/honors", name: "club-honors"};

export const CLUB_SHOW_MEMBERCARDS = "/club/membercards";
export const CLUB_SHOW_MEMBERS = "/club/members";
export const CLUB_SHOW_PAYMENTS = "/club/payment";
export const CLUB_SHOW_SPONSORS = "/club/sponsors-partners";
export const CLUB_SHOW_SQUADS = "/club/squads";
export const CLUB_SHOW_SYNC = "/club/sync";
export const CLUB_EDIT_MEMBER = "/club/edit-member";
export const CLUB_EDIT_SPONSOR = "/club/edit-sponsor";

export const CLUB_SHOW_LSPA = "/club/lspa";

export default {
  ROOT_PAGE,
  LOGIN_PAGE,
  START_PAGE,
  MEMBERCARD_PAGE,
  USER_PROFILE,
  USER_PROFILE_CHANGE_ADDRESS,
  USER_PROFILE_CHANGE_PASSWORD,
  USER_PROFILE_CHANGE_EMAIL,
  USER_PROFILE_CONFIRM_EMAIL,
  USER_PROFILE_CHANGE_BIRTHDAY,
  USER_PROFILE_CHANGE_SPORTS,
  USER_PROFILE_CHANGE_2FA,
  FORGOT_PASSWORD,
  PASSWORD_RESET,
  ONBOARDING,
  CREATE_ACCOUNT,
  CLUB_MEMBERS_PAGE,
  SINGLE_MEMBER_PAGE,
  FORM_WIZARD,
  TOTP,
  ADD_2FA_DEVICE,
  MEMBER_OFFERS,
  CLUB_EDIT_PROFILE,
  CLUB_SHOW_FIGURES,
  CLUB_SHOW_FUNCTIONS_AND_ACTIVITIES,
  CLUB_SHOW_GROUPS,
  CLUB_SHOW_GROUP_MEMBERS,
  CLUB_SHOW_GROUP_FUNCTIONS,
  CLUB_SHOW_HONORS,
  CLUB_SHOW_MEMBERCARDS,
  CLUB_SHOW_MEMBERS,
  CLUB_SHOW_PAYMENTS,
  CLUB_SHOW_SPONSORS,
  CLUB_SHOW_SQUADS,
  CLUB_SHOW_SYNC,
  CLUB_SHOW_LSPA,
  CLUB_EDIT_MEMBER,
  CLUB_EDIT_SPONSOR,
  CLUB_SHOW_GUARDIANS,
};
