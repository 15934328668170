import {defineStore} from "pinia";
import ApiClient from "@/api/ApiClient";

const INSTITUTE_BASE_PATH = '/api/institutes';
const EMPTY_DATE = '0001-01-01T00:00:00';


export const useInstituteStore = defineStore('institute', {
  state: () => {
    return {
      instituteList: [],
      institute: null,
      instituteFunctions: [],
      instituteAddress: [],
    };
  },

  actions: {
    /**
     * Loads the list of all "committees/groups" of a club/association.
     * This does not include committees/groups recorded at a lower level -> if needed use the function loadInstitutesForAssociation
     *
     * @param {number} clubNumber - The ID of the club or association.
     */
    async loadInstitutesForClub(clubNumber) {
      const instituteList = await ApiClient.getRequest(INSTITUTE_BASE_PATH + '/forClub?clubNo=' + clubNumber);
      if (instituteList?.status < 500 && instituteList?.data) {
        this.instituteList = instituteList.data;
      }
    },

    /**
     * Loads the list of all "committees/groups" of an association.
     * This also includes all committees/groups recorded at the club level, where the corresponding club belongs to the association.
     *
     * @param {string} associationId - The ID of the association.
     */
    async loadInstitutesForAssociation(associationId) {
      const instituteList = await ApiClient.getRequest(INSTITUTE_BASE_PATH + '/forAssociation?associationId=' + associationId);
      if (instituteList?.status < 500 && instituteList?.data) {
        this.instituteList = instituteList.data;
      }
    },

    async saveOrUpdateInstitute(payload) {
      if (!payload?.Code) {
        return await ApiClient.postRequestWithAuthorization(INSTITUTE_BASE_PATH + '/create', payload);
      } else if (payload?.Code) {
        return await ApiClient.putRequest(INSTITUTE_BASE_PATH + '/update', payload);
      }
      return null;
    },

    async deleteInstitute(payload) {
      if (payload?.Code && payload?.Club_No) {
        return await ApiClient.deleteRequest(INSTITUTE_BASE_PATH + '/delete?code=' + payload.Code + '&clubNo=' + payload.Club_No);
      }
      return null;
    },

    async loadAllInstitutes() {
      const instituteList = await ApiClient.getRequest(INSTITUTE_BASE_PATH + '/all');
      if (instituteList?.data) {
        this.instituteList = instituteList.data;
      }
    },

    async loadInstitute(instituteNumber, clubNumber) {
      const instituteData = await ApiClient.getRequest(INSTITUTE_BASE_PATH + '/byCode?code=' + instituteNumber + '&clubNo=' + clubNumber);
      if (instituteData) {
        this.institute = instituteData;
      }
    },

    async loadInstituteFunctionsForClub(clubNumber) {
      const functionData = await ApiClient.getRequest(INSTITUTE_BASE_PATH + '/functions/forClub?clubNo=' + clubNumber);
      if (functionData) {
        this.instituteFunctions = functionData;
      }
    },

    async deleteInstituteFunction(payload) {
      if (payload?.Code) {
        return await ApiClient.deleteRequest(INSTITUTE_BASE_PATH + '/functions/delete?code=' + payload.Code + '&clubNo=' + payload.Club_No);
      }
      return null;
    },

    async loadInstitueAddressesForClub(clubNumber, instituteCode) {
      const response = await ApiClient.getRequest(INSTITUTE_BASE_PATH + '/addresses?clubNo=' + clubNumber + '&code=' + instituteCode);
      if (response?.data) {
        this.instituteAddress = response.data;
        this.instituteAddress = this.instituteAddress.map(
          (address) => {
            if(address.startDate === EMPTY_DATE) {
              address.startDate = null;
            }
            if(address.endDate === EMPTY_DATE) {
              address.endDate = null;
            }
            return address;
          }
        )
      }
    },

    async saveOrUpdateInstituteAddress(payload) {
      if (payload && !payload.new) {
        return await ApiClient.putRequest(INSTITUTE_BASE_PATH
          + '/addresses/update', payload);
      } else if (payload) {
        return await ApiClient.postRequestWithAuthorization(INSTITUTE_BASE_PATH + '/addresses/create', payload);
      }
      return null;
    },

    async deleteInstituteAddress(entryNo) {
      if (entryNo) {
        return await ApiClient.deleteRequest(INSTITUTE_BASE_PATH + '/addresses/delete?entryNo=' + entryNo);
      }
      return null;
    },

    async saveOrUpdateInstituteFunction(payload) {
      if (payload && !payload.new) {
        return await ApiClient.putRequest(INSTITUTE_BASE_PATH + '/functions/update', payload);
      } else if (payload) {
        return await ApiClient.postRequestWithAuthorization(INSTITUTE_BASE_PATH + '/functions/create', payload);
      }
      return null;
    },
  },

  getters: {
    getInstituteListData: (state) => {
      if (state.instituteList) {
        return state.instituteList;
      }
      return [];
    },

    getInstituteData: (state) => {
      if (state.institute) {
        return state.institute;
      }
      return null;
    },

    getInstituteFunctions: (state) => {
      if (state.instituteFunctions) {
        return state.instituteFunctions.data;
      }
      return [];
    },

    getInstituteAddresses: (state) => {
      if (state.instituteAddress) {
        return state.instituteAddress;
      }
      return [];
    },

    getInstituteDescription: (state) => {

      return (instituteCode) => {
        if (state.instituteList) {
          let result = state.instituteList.find(i => i.Code === instituteCode);
          if (result) {
            return result.Description;
          }
        }
        return '';
      }
    }
  },

  persist: {
    storage: sessionStorage,
  },

});
