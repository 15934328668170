
const adultMinAge = 17;
const juniorMaxAge = 16;
const genderMale = 'M';
const genderFemale = 'W';
const genderAny = "ANY";
const standardCategoryArray = [10, 11, 18, 19, 24, 26];

export class MembershipCategoryInfo {
  constructor(category, allowedCategories, minAge, maxAge, gender) {
    this.category = category;
    this.allowedCategories = allowedCategories;
    this.minAge = minAge;
    this.maxAge = maxAge;
    this.gender = gender;
  }

  isCategoryCompatible(category) {
    const categoryAsNumber = Number(category);
    if(this.category === category) {
      return true;
    }
    return this.allowedCategories.includes(categoryAsNumber);
  }

  isGenderAllowed(gender) {
    if(this.gender === genderAny) {
      return true;
    }
    return this.gender === gender;
  }

  isBirthdayAllowed(birthday) {
    const ageInYears = new Date().getFullYear() - new Date(birthday).getFullYear(); // Schaut auf jahrgang (wird in diesem Jahr Alter ageInYears erreichen)
    return this.isAgeAllowed(ageInYears);
  }

  isAgeAllowed(age) {
    if(this.minAge !== 0) {
      if(age < this.minAge) {
        return false;
      }
    }
    if(this.maxAge !== 0) {
      if(age > this.maxAge) {
        return false;
      }
    }
    return true;
  }

}

const categoryArray = [];

categoryArray.push(new MembershipCategoryInfo("1", standardCategoryArray, adultMinAge, 0, genderMale));
categoryArray.push(new MembershipCategoryInfo("2", standardCategoryArray, adultMinAge, 0, genderMale));
categoryArray.push(new MembershipCategoryInfo("3", standardCategoryArray, adultMinAge, 0, genderMale));
categoryArray.push(new MembershipCategoryInfo("4", standardCategoryArray, adultMinAge, 0, genderFemale));
categoryArray.push(new MembershipCategoryInfo("5", standardCategoryArray, adultMinAge, 0, genderFemale));
categoryArray.push(new MembershipCategoryInfo("6", standardCategoryArray, adultMinAge, 0, genderFemale));
categoryArray.push(new MembershipCategoryInfo("7", standardCategoryArray, adultMinAge, 0, genderFemale));
categoryArray.push(new MembershipCategoryInfo("8", standardCategoryArray, adultMinAge, 0, genderMale));
categoryArray.push(new MembershipCategoryInfo("9", standardCategoryArray, adultMinAge, 0, genderAny));
categoryArray.push(new MembershipCategoryInfo("10", [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15,16,17,18,19,22,24,26,28,29], 0, 0, genderAny));
categoryArray.push(new MembershipCategoryInfo("11", [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15,16,17,18,19,22,24,26,28,29], 0, 0, genderAny));
categoryArray.push(new MembershipCategoryInfo("12", standardCategoryArray, 0, juniorMaxAge, genderMale));
categoryArray.push(new MembershipCategoryInfo("13", standardCategoryArray, 0, juniorMaxAge, genderFemale));
categoryArray.push(new MembershipCategoryInfo("14", standardCategoryArray, 0, juniorMaxAge, genderAny));
categoryArray.push(new MembershipCategoryInfo("15", standardCategoryArray, 0, juniorMaxAge, genderAny));
categoryArray.push(new MembershipCategoryInfo("16", standardCategoryArray, adultMinAge, 0, genderAny));
categoryArray.push(new MembershipCategoryInfo("17", standardCategoryArray, adultMinAge, 0, genderAny));
categoryArray.push(new MembershipCategoryInfo("18",[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,16,17,19,24,26,28,29], 0, 0, genderAny));
categoryArray.push(new MembershipCategoryInfo("19",[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,16,17,18,24,26,28,29], 0, 0, genderAny));
categoryArray.push(new MembershipCategoryInfo("22", standardCategoryArray, adultMinAge, 0, genderAny));
categoryArray.push(new MembershipCategoryInfo("24", [1,2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,16,17,18,19,22,26,28,29], adultMinAge, 0, genderAny));
categoryArray.push(new MembershipCategoryInfo("28", standardCategoryArray, adultMinAge, 0, genderAny));
categoryArray.push(new MembershipCategoryInfo("29", standardCategoryArray, 0, juniorMaxAge, genderAny));

export {categoryArray}
