<template>
  <Select v-if="locale === 'fr'" :options="counties" optionLabel="nameFr" optionValue="code" checkmark :aria-label="t('club.member.county')" />
  <Select v-else :options="counties" optionLabel="nameDe" optionValue="code" checkmark :aria-label="t('club.member.county')" />
</template>

<script setup>
import Select from "primevue/select";
import {useI18n} from "vue-i18n";
import {useMemberStore} from "@/store/modules/member/member";
import {computed, onBeforeMount} from "vue";

const {t, locale} = useI18n();
const memberStore = useMemberStore();

const counties = computed(() => {
  return memberStore.getCounties;
});

onBeforeMount(async () => {
  await memberStore.loadCounties(locale.value);
});
</script>
