<template>
  <div>
    <div class="row">
      <div class="col">
        <h5 class="mb-3">{{ $t('club.functionsAndActivities.activity.titleList') }}</h5>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <DataTable :value="members"
                   scrollable scroll-height="80vh"
                   :sort-field="sortColumn" :sort-order="1" @sort="onSort"
                   ref="memberTable"
                   v-model:filters="filters" filter-display="row"
                   :paginator="members.length > 50" :rows="50" :rows-per-page-options="[50, 100, 150, 200]"
                   paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
                   :currentPageReportTemplate="`{first}` + ' ' + $t('pagination.to') + ' ' + `{last}` + ' ' + $t('pagination.of') + ' ' +  `{totalRecords}`"
                   csv-separator=";" :export-filename="$t('club.functionsAndActivities.activity.titleList')" :export-function="csvExportTransformationFunction"
                   data-key="vvaId"
                   :loading="isLoading"
                   row-hover
                   class="border-bottom">

          <template #empty>
            <div class="alert alert-info">{{ $t('club.functionsAndActivities.activity.notFound') }}</div>
          </template>

          <template #paginatorfirstpagelinkicon>
            <i class="bi bi-arrow-bar-left"/>
          </template>
          <template #paginatorprevpagelinkicon>
            <i class="bi bi-arrow-left"/>
          </template>
          <template #paginatornextpagelinkicon>
            <i class="bi bi-arrow-right"/>
          </template>
          <template #paginatorlastpagelinkicon>
            <i class="bi bi-arrow-bar-right"/>
          </template>

          <Column :exportable="false">
            <template #body="{data}">
              <div class="btn-group btn-group-sm pe-2">
                <Button :title="$t('club.functionsAndActivities.activity.edit.title')" :aria-label="$t('club.functionsAndActivities.activity.edit.title')"
                        unstyled class="btn btn-outline-secondary" @click="editMember(data)" data-bs-toggle="modal" data-bs-target="#edit-member-activity-modal">
                  <i class="bi bi-pencil"></i>
                </Button>
                <Button :title="$t('club.functionsAndActivities.activity.delete.title')" :aria-label="$t('club.functionsAndActivities.activity.delete.title')"
                        unstyled class="btn btn-outline-primary" @click="removeMemberConfirm(data)">
                  <i class="bi bi-trash"></i>
                </Button>
              </div>
            </template>
          </Column>

          <Column v-for="col in columns" :key="col.field" :field="col.field"
                  :filter-field="col.field" filter-header-class="table-header-filter" :show-filter-menu="false"
                  :header="col.header"
                  :sortable="col.sortable"
                  :header-class="(sortColumn === col.field ? 'tableHeader text-primary' : 'tableHeader')"
                  :exportable="col.exportable"
                  :hidden="!col.visible">
            <template #body="{data}">
              {{ formatEntry(col.type, data[col.field]) }}
            </template>
            <template #filter="{ filterModel, filterCallback }" v-if="col.filterable">
              <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('section.clubMembers.filter', {filter: col.header})" class="form-control input-filter"/>
            </template>
          </Column>

        </DataTable>

      </div>
    </div>

    <div class="row w-100 flex-center gap-2 mb-3 mt-3">
      <Button title="Export" label="Export" @click="exportCSV" unstyled class="btn btn-outline-primary datatable-export width-auto">
        <i class="bi bi-filetype-csv"></i>
      </Button>
      <Button :title="$t('club.functionsAndActivities.activity.titleNewMemberActivity')"
              :label="$t('club.functionsAndActivities.activity.titleNewMemberActivity')"
              unstyled class="btn btn-outline-secondary width-auto" icon="bi bi-plus-circle me-2"
              data-bs-toggle="modal" data-bs-target="#edit-member-activity-modal"
              @click="addMemberActivity()"/>
    </div>
    <MemberActivityEditModal id="edit-member-activity-modal" @member-activities-changed="loadClubMembersWithActivities" :createNew="createNew" :activity="memberToEdit" :clubId="props.clubId"></MemberActivityEditModal>

  </div>
</template>

<script setup>

import {onBeforeMount, ref, watch} from "vue";
import MemberActivityEditModal from "@/components/activity/MemberActivityEditModal.vue";
import ApiClient from "@/api/ApiClient";
import Button from "primevue/button";
import {FilterMatchMode} from "@primevue/core/api";
import moment from "moment/moment";
import {useI18n} from "vue-i18n";
import {useConfirm} from "primevue/useconfirm";
import Message from "@/helpers/message";
import {useMessagesStore} from "@/store/modules/messages/messages";

const props = defineProps({
    clubId: String
  }
);
const {t} = useI18n();

const isLoading = ref(true);
const members = ref([]);

const sortColumn = ref('description');

const filters = ref({
  description: {value: null, matchMode: FilterMatchMode.CONTAINS},
  memberLastName: {value: null, matchMode: FilterMatchMode.CONTAINS},
  firstYear: {value: null, matchMode: FilterMatchMode.CONTAINS},
  lastYear: {value: null, matchMode: FilterMatchMode.CONTAINS}
});

const columns = [
  {field: 'description', header: t('club.functionsAndActivities.activity.title'), filterable: true, exportable: true, sortable: true, visible: true, type: 'String'},
  {field: 'firstYear', header: t('club.functionsAndActivities.activity.yearStart'), filterable: true, exportable: true, sortable: true, visible: true, type: 'Year'},
  {field: 'lastYear', header: t('club.functionsAndActivities.activity.yearEnd'), filterable: true, exportable: true, sortable: true, visible: true, type: 'Year'},
  {field: 'pointsPerYear', header: t('club.functionsAndActivities.activity.pointsEachYear'), filterable: false, exportable: true, sortable: true, visible: true, type: 'String'},
  {field: 'contactNo', header: t('club.functionsAndActivities.list.contactNo'), filterable: false, exportable: true, sortable: true, visible: true, type: 'String'},
  {field: 'memberSalutationCode', header: t('club.listMember.salutation'), filterable: false, exportable: true, sortable: false, visible: true, type: 'Salutation'},
  {field: 'memberLastName', header: t('club.listMember.surname'), filterable: true, exportable: true, sortable: true, visible: true, type: 'String'},
  {field: 'memberFirstName', header: t('club.listMember.firstName'), filterable: false, exportable: true, sortable: true, visible: true, type: 'String'},
  {field: 'memberBirthday', header: t('club.listMember.birthday'), filterable: false, exportable: true, sortable: true, visible: true, type: 'Date'},
  {field: 'memberAddress', header: t('club.listMember.address'), filterable: false, exportable: true, sortable: true, visible: false, type: 'Date'},
  {field: 'memberPostcode', header: t('club.listMember.zipCode'), filterable: false, exportable: true, sortable: true, visible: false, type: 'String'},
  {field: 'memberCity', header: t('club.listMember.place'), filterable: false, exportable: true, sortable: true, visible: false, type: 'String'},
  {field: 'memberCountry', header: t('club.member.country'), filterable: false, exportable: true, sortable: true, visible: false, type: 'String'},
  {field: 'memberEmail', header: t('club.listMember.eMailPrivate'), filterable: false, exportable: true, sortable: true, visible: false, type: 'String'},
  {field: 'memberEmailClub', header: t('club.listMember.eMailVerein'), filterable: false, exportable: true, sortable: true, visible: false, type: 'String'},
  {field: 'memberPhone', header: t('club.listMember.phonePrivate'), filterable: false, exportable: true, sortable: true, visible: false, type: 'String'},
  {field: 'memberPhoneMobile', header: t('club.listMember.phoneMobile'), filterable: false, exportable: true, sortable: true, visible: false, type: 'String'},
];

const onSort = (event) => {
  sortColumn.value = event.sortField;
};

async function loadClubMembersWithActivities() {
  const request = "/api/activity/member?clubId=" + props.clubId;

  const response = await ApiClient.getRequest(request);

  if (response && response.status === 200) {
    isLoading.value = false;
    members.value = response.data;
  }
}

watch(props, () => {
  loadClubMembersWithActivities();
})

onBeforeMount(() => {
  isLoading.value = true;
  loadClubMembersWithActivities();
})

const memberToEdit = ref(null);
const createNew = ref(false);

function editMember(member) {
  memberToEdit.value = member;
  createNew.value = false;
}

function addMemberActivity() {
  memberToEdit.value = {
    firstYear: 0,
    lastYear: 0
  }
  createNew.value = true;
}

const confirm = useConfirm();

function removeMemberConfirm(member) {
  confirm.require({
    header: t('club.functionsAndActivities.activity.deleteHeader'),
    message: t('club.functionsAndActivities.activity.deleteQuestion'),
    icon: 'bi bi-exclamation-triangle',
    acceptProps: {
      label: t('club.functionsAndActivities.activity.deleteConfirmButton'),
      unstyled: true,
      class: 'btn btn-outline-primary',
    },
    rejectProps: {
      label: t('cancel'),
      unstyled: true,
      class: 'btn btn-outline-secondary',
    },
    accept: () => {
      removeMember(member);
    },
  });
}

const messagesStore = useMessagesStore();

async function removeMember(member) {

  const request = "/api/activity/member?vvaId=" + member.vvaId;

  const response = await ApiClient.deleteRequest(request);

  if (response.status && response.status === 204) {
    messagesStore.addInfo(new Message('info', true, false, t('club.functionsAndActivities.activity.deleteSuccessHeader'), "", t('club.functionsAndActivities.activity.deleteSuccess')));
    await loadClubMembersWithActivities();
  } else if (response.status && response.status === 404) {
    messagesStore.addError(new Message('error', true, false, t('club.functionsAndActivities.activity.deleteNotFoundHeader'), "", t('club.functionsAndActivities.activity.deleteNotFound')));
  } else {
    messagesStore.addError(new Message('error', true, false, t('errorMessages.generic_error'), "", t('errorMessages.generic_error')));
  }
}

const formatEntry = (type, value) => {
  switch (type) {
    case 'Date':
      return formatDate(value);
    case 'Year':
      if (value === 0) {
        return ""; // Jahr 0 bedeutet kein Jahr gesetzt
      }
      return value;
    case 'Salutation':
      if (value === 'W') {
        return t('club.listMember.salutationMS');
      } else if (value === 'M') {
        return t('club.listMember.salutationMR');
      }
      return value;
    default:
      return value;
  }
}

const formatDate = (value, format) => {
  if (value) {
    const dateValue = moment(String(value));
    if (dateValue && dateValue.isAfter('1900-01-01')) {
      return dateValue.format(format || 'DD.MM.YYYY');
    }
  }
  return '–';
}

const memberTable = ref();

const exportCSV = () => {
  memberTable.value.exportCSV({selectionOnly: false})
};

const csvExportTransformationFunction = (record) => {
  switch (record.field) {
    case 'memberSalutationCode':
      return formatEntry('Salutation', record.data);
    case 'memberBirthday':
      return formatEntry('Date', record.data);
    case 'firstYear':
    case 'lastYear':
      return formatEntry('Year', record.data);
    default:
      return String(record.data)
  }
}
</script>
