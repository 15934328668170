<template>
  <MetaNavigation/>

  <section id="clubMemberSection" class="membercard-container club-container  py-4">
    <div class="col-12">
      <h1>{{ vereinName }}</h1>
      <h6 class="text-secondary mt-5" v-if="verbandName">{{ $t('association.association') }}</h6>{{ verbandName }}
      <hr class="my-5">
      <h4>{{ $t('section.clubMembers.members') }}</h4>

      <DataTable :value="lspaClubMembers"
                 scrollable scroll-height="60vh"
                 :sort-field="sortColumn" :sort-order="1"
                 @sort="onSort"
                 v-model:selection="selection"
                 data-key="memberId"
                 v-model:filters="filters" filter-display="row"
                 ref="dt"
                 :row-class="rowClassHook"
                 :select-all="selectAll"
                 @select-all-change="selectAllChangeHook"
                 @row-unselect="rowUnselectHook"
                 :loading="isLoading"
                 :paginator="lspaClubMembers.length > 50" :rows="50" :rows-per-page-options="[50, 100, 150, 200]"
                 paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
                 :currentPageReportTemplate="`{first}` + ' ' + $t('pagination.to') + ' ' + `{last}` + ' ' + $t('pagination.of') + ' ' +  `{totalRecords}`"
                 csv-separator=";"
                 :export-filename="$t('lspa.exportFilename')"
                 :export-function="exportFunction"
                 row-hover
                 class="border-bottom">

        <template #empty>
          <div class="alert alert-info">{{ $t('noData') }}</div>
        </template>
        <template #paginatorfirstpagelinkicon>
          <i class="bi bi-arrow-bar-left"/>
        </template>
        <template #paginatorprevpagelinkicon>
          <i class="bi bi-arrow-left"/>
        </template>
        <template #paginatornextpagelinkicon>
          <i class="bi bi-arrow-right"/>
        </template>
        <template #paginatorlastpagelinkicon>
          <i class="bi bi-arrow-bar-right"/>
        </template>

        <Column v-if="lspaOrderPeriodIsActive" selection-mode="multiple" class="dt-col-2"/>

        <Column field="memberId" filter-header-class="table-header-filter" :show-filter-menu="false"
                :header-class="(sortColumn === 'memberId' ? 'tableHeader text-primary' : 'tableHeader')"
                :header="$t('personalData.person.stvNumber')" sortable>
          <template #body="{ data }">
            {{ formatEntry('String', data.memberId) }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()"
                       :placeholder="$t('filter')" class="form-control input-filter"/>
          </template>
        </Column>

        <Column field="firstName" filter-field="firstName" filter-header-class="table-header-filter" :show-filter-menu="false"
                :header-class="(sortColumn === 'firstName' ? 'tableHeader text-primary' : 'tableHeader')"
                :header="$t('personalData.person.firstName')">
          <template #body="{ data }">
            {{ formatEntry('String', data.firstName) }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()"
                       :placeholder="$t('filter')" class="form-control input-filter"/>
          </template>
        </Column>

        <Column field="lastName" filter-field="lastName" filter-header-class="table-header-filter" :show-filter-menu="false"
                :header-class="(sortColumn === 'lastName' ? 'tableHeader text-primary' : 'tableHeader')"
                :header="$t('personalData.person.surname')" sortable>
          <template #body="{ data }">
            {{ formatEntry('String', data.lastName) }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()"
                       :placeholder="$t('filter')" class="form-control input-filter"/>
          </template>
        </Column>

        <Column field="birthday" filter-field="birthday" filter-header-class="table-header-filter" :show-filter-menu="false"
                :header-class="(sortColumn === 'birthday' ? 'tableHeader text-primary' : 'tableHeader wrap')"
                :header="$t('personalData.person.birthday')" sortable>
          <template #body="{ data }">
            {{ formatEntry('Date', data.birthday) }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()"
                       :placeholder="$t('filter')" class="form-control input-filter"/>
          </template>
        </Column>

        <Column field="issuingClubName"
                :header-class="'tableHeader'"
                :header="$t('lspa.clubName')"
                style="white-space:pre-wrap">
          <template #body="{ data }">
            {{ formatEntry('String', data.issuingClubName) }}
          </template>

        </Column>

        <Column field="sportId" filter-field="sportId" filter-header-class="table-header-filter" :show-filter-menu="false"
                :header-class="(sortColumn === 'sportId' ? 'tableHeader text-primary' : 'tableHeader')"
                :header="$t('lspa.sportId')">
          <template #body="{ data }">
            {{ formatEntry('Sportart', data.sportId) }}
          </template>
        </Column>

        <Column field="latestYearValid" filter-field="latestYearValid" filter-header-class="table-header-filter" :show-filter-menu="false"
                :header-class="(sortColumn === 'latestYearValid' ? 'tableHeader text-primary wrap' : 'tableHeader wrap')"
                :header="$t('lspa.validYear')" sortable>
          <template #body="{ data }">
            {{ formatEntry('Number', data.latestYearValid) }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()"
                       :placeholder="$t('filter')" class="form-control input-filter"/>
          </template>
        </Column>

        <Column field="status" filter-field="status" filter-header-class="table-header-filter" :show-filter-menu="false"
                :header-class="(sortColumn === 'status' ? 'tableHeader text-primary' : 'tableHeader')"
                :header="$t('lspa.status.status')" sortable>

          <template #body="{ data }">
            {{ formatEntry('Status', data.status) }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Select v-model="filterModel.value" @change="filterCallback()" :options="possibleStatus" :placeholder="$t('filter')" :showClear="true">
              <template #value="slotProps">
                <div v-if="slotProps.value" class="flex items-center">
                  <div>{{ $t('lspa.status.' + slotProps.value) }}</div>
                </div>
                <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
              </template>
              <template #option="slotProps">
                {{ $t('lspa.status.' + slotProps.option) }}
              </template>
            </Select>
          </template>
        </Column>
      </DataTable>

    </div>
    <LoaderOverlay v-if="isLoading"/>
  </section>

  <Section-container class="p-0 mt-4">
    <div class="p-2" v-if="lspaOrderPeriodIsActive">
      <Button class="btn btn-outline-secondary m-1" unstyled type="button" id="renew-old-lspa-button" :label="$t('lspa.renewOld')"
              :disabled="selection.length === 0" @click="renew"/>
      <Button class="btn btn-outline-secondary m-1" unstyled type="button" id="new-lspa-modal-toggle" :label="$t('lspa.createNew')"
              data-bs-toggle="modal" data-bs-target="#create-new-lspa-modal"/>
      <Button title="Export" label="Export" unstyled @click="exportCSV($event)" class="datatable-export m-1 width-auto btn btn-outline-primary">
        <i class="bi bi-filetype-csv"></i>
      </Button>
    </div>
    <div class="alert alert-info align-items-start" style="margin: 1rem">
      {{ $t('lspa.limitedPeriodInfotext') }}
      <div v-if="!lspaOrderPeriodIsActive">
        {{ $t('lspa.limitedPeriodHasPassedInfoText') }}
      </div>
    </div>
    <CreateNewLSPAModal id="create-new-lspa-modal" :lspaClubId="lspaClubId" @lspa-club-member-changed="reloadClubMembersCurrentClub"/>
    <ConfirmDialog :pt="{footer: {class: 'd-flex justify-content-between flex-row-reverse'}}"></ConfirmDialog>
  </Section-container>

  <BootstrapToast/>

  <section-container id="faq-info" class="p-0 mt-4">
    <FaqPanel class="m-0 border-0"/>
  </section-container>

</template>

<style scoped>
:deep(.wrap) {
  white-space: normal;
}

:deep(.selective-disable .p-checkbox) {
  display: none;
  cursor: default !important;
  pointer-events: none;
  user-select: none;
}

</style>

<script setup>

import CreateNewLSPAModal from "@/components/ui/lspa/CreateNewLSPAModal.vue";
import BootstrapToast from '@/components/ui/BootstrapToast.vue';
import {FilterMatchMode} from "@primevue/core/api"
import {useConfirm} from "primevue/useconfirm";

import {computed, onMounted, ref, watch} from 'vue'
import {useRoute} from 'vue-router'
import {useMemberStore} from "@/store/modules/member/member.js";
import {useMessagesStore} from "@/store/modules/messages/messages.js";
import {useClubStore} from "@/store/modules/club/club.js";
import ApiClient from "@/api/ApiClient";

import MetaNavigation from '@/components/navigation/MetaNavigation';
import SectionContainer from "@/components/ui/SectionContainer";
import FaqPanel from "@/components/ui/FaqPanel.vue";
import LoaderOverlay from "@/components/ui/LoaderOverlay.vue";
import {useI18n} from "vue-i18n";
import moment from "moment";
import Message from "@/helpers/message";

import Select from 'primevue/select';
import Button from "primevue/button";

const route = useRoute()
const clubStore = useClubStore();
const memberStore = useMemberStore();
const messagesStore = useMessagesStore();
const confirm = useConfirm();

const isSelectable = ref(false);
const isLoading = ref(false);
const selection = ref([]);

const currentYear = ref(new Date().getFullYear());

const sortColumn = ref("memberId");

const lspaClubMembers = ref([]);
const lspaClubId = ref();

const possibleStatus = ref([ 'ACTIVE', 'EXPIRED', 'NO_ACTIVE_MEMBERSHIP' ])

const {t} = useI18n();


const filters = ref({
  memberId: {value: null, matchMode: FilterMatchMode.CONTAINS},
  lastName: {value: null, matchMode: FilterMatchMode.CONTAINS},
  firstName: {value: null, matchMode: FilterMatchMode.CONTAINS},
  birthday: {value: null, matchMode: FilterMatchMode.CONTAINS},
  clubNames: {value: null, matchMode: FilterMatchMode.CONTAINS},
  latestYearValid: {value: null, matchMode: FilterMatchMode.CONTAINS},
  status: {value: null, matchMode: FilterMatchMode.EQUALS},
});

function renew() {
  if (selection.value.length === 0) {
    return;
  }
  confirm.require({
    message: t('lspa.confirmOrderRenew', {currentYear: currentYear.value}),
    header: t('lspa.confirmOrderRenewHeader'),
    rejectProps: {
      label: t('lspa.cancel'),
      unstyled: true,
      class: 'btn btn-outline-secondary',
    },
    acceptProps: {
      label: t('lspa.confirmOrderRenewButton'),
      unstyled: true,
      class: 'btn btn-outline-primary'
    },
    accept: async () => {
      const request = "/api/lspa/renew";
      const membershipNrs = selection.value.map((object) => {
        return object.memberId
      })
      const body = {
        lspaClubId: lspaClubId.value,
        membershipNrs: membershipNrs
      }

      const response = await ApiClient.postRequestWithAuthorization(request, body);
      if (response.data.error) {
        messagesStore.addError(new Message('error', true, false, t('errorMessages.generic_error'), "", response.data.error.message));
      } else if (response.status === 201) {
        messagesStore.addInfo(new Message('info', true, false,
          t('lspa.successCreatingNewHeader'), "", t('lspa.successCreatingNew')));
        selection.value = [];
      } else {
        if (response.data?.errors?.length) {
          response.data.errors.forEach(e => {
            messagesStore.addError(new Message('error', true, false, t('errorMessages.generic_error'), "", e));
          });
        } else {
          messagesStore.addError(new Message('error', true, false, t('errorMessages.generic_error'), "", t('errorMessages.generic_error')));
        }
      }
      await reloadClubMembersCurrentClub();
    }
  });
}


// Da PrimeVue keine Möglichkeit bietet, einzelne Columns nicht anwählbar zu machen, musste ich das
// mit diesen Methoden hinbiegen. Kopiert von https://stackblitz.com/edit/3z633b?file=src%2FApp.vue
const selectAll = ref(false);
const dt = ref(); //Data Table ref

const rowUnselectHook = () => {
  selectAll.value = false;
};

const selectAllChangeHook = (event) => {
  selectAll.value = event.checked;
  if (event.checked) {
    // processedData ist der Datensatz der DataTable nachdem Filter angewendet wurden.
    selection.value = dt.value.processedData.filter(columnIsSelectable);
  } else {
    selection.value = [];
  }
};

const columnIsSelectable = (member) => member.status === "EXPIRED";

const rowClassHook = (row) => {
  if (!columnIsSelectable(row)) {
    return 'selective-disable'
  }
  return '';
}

const vereinName = computed(() => {
  let club = null;
  if (memberData.value.functions) {
    club = memberData.value.functions.find(f => {
      return !!f && f.organId === currentClubId.value;
    });
  }
  return club != null && club.organ !== null ? club.organ : '';
})

const verbandName = computed(() => {
  let membership = null;
  if (memberData.value.memberships) {
    membership = memberData.value.memberships.find(m => {
      return !!m.verein && m.verein.code === currentClubId.value
    });
  }
  return membership != null && membership.verband !== null ? membership.verband.description : '';
})

const memberData = computed(() => {
  return memberStore.getMemberData;
})


const currentClubId = computed(() => {
  return clubStore.getCurrentClubId;
})

const lspaOrderPeriodIsActive = computed(() => {
  //const today = new Date();
  return true; // TODO change back
  //return today.getMonth() < 4; // null-indexed, also Monat kleiner als Mai
})

const formatEntry = (type, value) => {
  switch (type) {
    case 'Date':
      return formatDate(value);
    case 'Array':
      return [ ...new Set(Array.prototype.map.call(value, s => s)) ].join('\n');
    case 'Salutation':
      if (value === 'MS') {
        return t('club.listMember.salutationMS');
      } else if (value === 'MR') {
        return t('club.listMember.salutationMR');
      }
      return value;
    case 'Language':
      if (value === 'de' || value === 'DE') {
        return t('languages.de');
      } else if (value === 'fr' || value === 'FR') {
        return t('languages.fr');
      }
      return value;
    case 'Sportart':
      return t('sport.' + value);
    case 'Status':
      return t('lspa.status.' + value);
    default:
      return value;
  }
}

const formatDate = (value, format) => {
  if (value) {
    const dateValue = moment(String(value));
    if (dateValue && dateValue.isAfter('1900-01-01')) {
      return dateValue.format(format || 'DD.MM.YYYY');
    }
  }
  return '–';
}
const exportCSV = () => {
  dt.value.exportCSV();
}

const exportFunction = (entry) => {
  switch (entry.field) {
    case 'salutation':
      return formatEntry('Salutation', entry.data);
    case 'language':
      return formatEntry('Language', entry.data);
    case 'status':
      return formatEntry('Status', entry.data);
    case 'sportId':
      return formatEntry('Sportart', entry.data);
    case 'date':
      return formatEntry('Date', entry.data);
    default:
      return String(entry.data)
  }
}

async function reloadClubMembersCurrentClub() {
  await reloadClubMembers(lspaClubId.value, false);
}


async function reloadClubMembers(clubId, showLoadingIndicator) {
  if (showLoadingIndicator) {
    isLoading.value = true;
  }
  const request = "/api/lspa/members?lspaClubId=" + clubId;

  const response = await ApiClient.getRequest(request);

  if (response && response.status === 200) {
    lspaClubMembers.value = response.data;
  } else if (response.status === 403) {
    messagesStore.addError(new Message('error', true, false, t('errorMessages.access_denied_header'), "", t('errorMessages.access_denied_text')));
  } else if (response.data) {
    messagesStore.addError(new Message('error', true, false, t('errorMessages.generic_error'), "", response.data.error.message));
  } else {
    messagesStore.addError(new Message('error', true, false, t('errorMessages.generic_error'), "", t('errorMessages.generic_error')));
  }
  isLoading.value = false;
}

const onSort = (event) => {
  sortColumn.value = event.sortField;
}

watch(
  () => route.query.clubId,
  async newClubId => {
    lspaClubId.value = newClubId;
    await reloadClubMembers(newClubId, true);
  }, {immediate: true}
)

onMounted(() => {
  isSelectable.value = selection.value.length > 0;
})
</script>
