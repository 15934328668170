<template>
  <Select filter :placeholder="t('club.member.country')" :options="countries" option-value="code" :option-label="locale === 'fr' ? 'nameFr' : 'nameDe'">
    <template #value="slotProps">
      <div class="flex items-center" v-if="slotProps.value">
        <img src="@/assets/img/flag_placeholder.png" alt="" :class="`vti__flag ${slotProps.value.toLowerCase()}`">
        <div v-if="locale === 'fr'">{{ getCountry(slotProps.value).nameFr }}</div>
        <div v-else>{{ getCountry(slotProps.value).nameDe }}</div>
      </div>
      <div v-else>
        {{ slotProps.placeholder }}
      </div>
    </template>
    <template #option="slotProps">
      <div class="flex items-center" v-if="locale === 'fr'">
        <img src="@/assets/img/flag_placeholder.png" alt="" :class="`vti__flag ${slotProps.option.code.toLowerCase()}`">
        <div>{{ slotProps.option.nameFr }}</div>
      </div>
      <div class="flex items-center" v-else>
        <img src="@/assets/img/flag_placeholder.png" alt="" :class="`vti__flag ${slotProps.option.code.toLowerCase()}`">
        <div>{{ slotProps.option.nameDe }}</div>
      </div>
    </template>
  </Select>
</template>

<script setup>
import Select from "primevue/select";
import {useI18n} from "vue-i18n";
import {useMemberStore} from "@/store/modules/member/member";
import {computed, onBeforeMount} from "vue";

const {t, locale} = useI18n();
const memberStore = useMemberStore();
const countries = computed(() => {
  return memberStore.getCountries;
});

const getCountry = (countryCode) => {
  if (countries.value?.length > 0) {
    return countries.value.find(c => c.code === countryCode);
  }
  return {};
};

onBeforeMount(async () => {
  await memberStore.loadCountries(locale.value);
});
</script>
