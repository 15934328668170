<template>
  <club-layout :title="clubData.status === 200 ? $t('club.profile') + ' ' + clubData.data.Name : $t('club.notFound.title')" :is-loading="false">
    <template #content v-if="clubData.status === 200">

      <div class="col-12">
        <DataTable :value="clubMembers"
                   :sortField="sortColumn" :sortOrder="1" @sort="onSort"
                   v-model:selection="selection"
                   dataKey="memberId"
                   scrollable
                   v-model:filters="filters" filterDisplay="row"
                   :loading="isLoading"
                   ref="dt"
                   paginator :rows="50" :rowsPerPageOptions="[20, 50, 100, 150]"
                   paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
                   :currentPageReportTemplate="`{first}` + ' ' + $t('section.clubMembers.pagination.to') + ' ' + `{last}` + ' ' + $t('section.clubMembers.pagination.of') + ' ' +  `{totalRecords}`"
                   csvSeparator=";" :exportFilename="$t('section.clubMembers.exportFilename')" :exportFunction="exportFunction"
                   stripedRows>


          <template #empty>{{ $t('section.clubMembers.noMembersFound') }}</template>
          <template #paginatorfirstpagelinkicon>
            <i class="bi bi-arrow-bar-left"/>
          </template>
          <template #paginatorprevpagelinkicon>
            <i class="bi bi-arrow-left"/>
          </template>
          <template #paginatornextpagelinkicon>
            <i class="bi bi-arrow-right"/>
          </template>
          <template #paginatorlastpagelinkicon>
            <i class="bi bi-arrow-bar-right"/>
          </template>

          <Column selectionMode="multiple" class="dt-col-2"></Column>

          <Column field="memberId" filterField="memberId" :header="$t('club.listMember.nr')" sortField="memberId" sortable
                  :headerClass="(sortColumn === 'memberId' ? 'tableHeader text-primary' : 'tableHeader')" filterHeaderClass="table-header-filter"
                  bodyClass="table-cell-link" :showFilterMenu="false" :exportable="true">
            <template #body="{ data }">
              <router-link :to="{name: 'club-edit-member', query: {memberId: data.memberId, clubId: currentClubId}}" class="table-link" @click="isLoading = true" @keyup.enter="isLoading = true" @keyup.space="isLoading = true">
                {{ formatEntry('String', data.memberId) }}
              </router-link>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('search.search')" class="form-control input-filter"/>
            </template>
          </Column>

          <Column field="salutation" fieldField="salutation" filterHeaderClass="table-header-filter" :showFilterMenu="false"
                  :header="$t('club.listMember.salutation')" sortField="salutation" sortable
                  :headerClass="(sortColumn === 'salutation' ? 'tableHeader text-primary' : 'tableHeader')" bodyClass="table-cell-link" :exportable="true">
            <template #body="{ data }">
              <router-link :to="{name: 'club-edit-member', query: {memberId: data.memberId, clubId: currentClubId}}" class="table-link" @click="isLoading = true" @keyup.enter="isLoading = true" @keyup.space="isLoading = true">
                {{ formatEntry('Salutation', data.salutation) }}
              </router-link>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <Select v-model="filterModel.value" @change="filterCallback()" :options="possibleSalutations" :placeholder="$t('search.filter')" :showClear="true">
                <template #value="slotProps">
                  <div v-if="slotProps.value" class="flex items-center">
                    <div>{{ $t('club.listMember.salutation' + slotProps.value) }}</div>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder}}
                  </span>
                </template>
                <template #option="slotProps">
                  {{ $t('club.listMember.salutation' + slotProps.option) }}
                </template>
              </Select>
            </template>
          </Column>

          <Column field="surname" filterField="surname" :header="$t('club.listMember.surname')" sortField="surname" sortable
                  :headerClass="(sortColumn === 'surname' ? 'tableHeader text-primary' : 'tableHeader')" filterHeaderClass="table-header-filter"
                  bodyClass="table-cell-link" :showFilterMenu="false" >
            <template #body="{ data }">
              <router-link :to="{name: 'club-edit-member', query: {memberId: data.memberId, clubId: currentClubId}}" class="table-link" @click="isLoading = true" @keyup.enter="isLoading = true" @keyup.space="isLoading = true">
                {{ formatEntry('String', data.surname) }}
              </router-link>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('search.search')" class="form-control input-filter"/>
            </template>
          </Column>

          <Column field="firstName" filterField="firstName" :header="$t('club.listMember.firstName')" sortField="firstName"
                  sortable :headerClass="(sortColumn === 'firstName' ? 'tableHeader text-primary' : 'tableHeader')"
                  filterHeaderClass="table-header-filter" bodyClass="table-cell-link" :showFilterMenu="false" >
            <template #body="{ data }">
              <router-link :to="{name: 'club-edit-member', query: {memberId: data.memberId, clubId: currentClubId}}" class="table-link" @click="isLoading = true" @keyup.enter="isLoading = true" @keyup.space="isLoading = true">
                {{ formatEntry('String', data.firstName) }}
              </router-link>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('search.search')" class="form-control input-filter"/>
            </template>
          </Column>

          <Column field="addition" fieldField="addition" :header="$t('club.member.addressAddition')" :hidden="true" >
            <template #body="{ data }">
              {{ formatEntry('String', data.addition)}}
            </template>
          </Column>

          <Column field="addition2" fieldField="addition2" :header="$t('club.member.addressAddition2')" :hidden="true" >
            <template #body="{ data }">
              {{ formatEntry('String', data.addition2)}}
            </template>
          </Column>

          <Column field="address" fieldField="address" filterHeaderClass="table-header-filter" :showFilterMenu="false"
                  :header="$t('club.listMember.address')" sortField="address" sortable
                  :headerClass="(sortColumn === 'address' ? 'tableHeader text-primary' : 'tableHeader')" bodyClass="table-cell-link" >
            <template #body="{ data }">
              <router-link :to="{name: 'club-edit-member', query: {memberId: data.memberId, clubId: currentClubId}}" class="table-link" @click="isLoading = true" @keyup.enter="isLoading = true" @keyup.space="isLoading = true">
                {{ formatEntry('String', data.address) }}
              </router-link>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('search.search')" class="form-control input-filter"/>
            </template>
          </Column>

          <Column field="postCode" fieldField="postCode" filterHeaderClass="table-header-filter" :showFilterMenu="false" :header="$t('club.listMember.zipCode')" sortField="postCode" sortable :headerClass="(sortColumn === 'postCode' ? 'tableHeader text-primary' : 'tableHeader')" bodyClass="table-cell-link" >
            <template #body="{ data }">
              <router-link :to="{name: 'club-edit-member', query: {memberId: data.memberId, clubId: currentClubId}}" class="table-link" @click="isLoading = true" @keyup.enter="isLoading = true" @keyup.space="isLoading = true">
                {{ formatEntry('String', data.postCode) }}
              </router-link>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('search.search')" class="form-control input-filter"/>
            </template>
          </Column>

          <Column field="city" fieldField="city" filterHeaderClass="table-header-filter" :showFilterMenu="false" :header="$t('club.listMember.place')" sortField="city" sortable :headerClass="(sortColumn === 'city' ? 'tableHeader text-primary' : 'tableHeader')" bodyClass="table-cell-link" >
            <template #body="{ data }">
              <router-link :to="{name: 'club-edit-member', query: {memberId: data.memberId, clubId: currentClubId}}" class="table-link" @click="isLoading = true" @keyup.enter="isLoading = true" @keyup.space="isLoading = true">
                {{ formatEntry('String', data.city) }}
              </router-link>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('search.search')" class="form-control input-filter"/>
            </template>
          </Column>

          <Column field="birthday" fieldField="birthday" filterHeaderClass="table-header-filter" :showFilterMenu="false" :header="$t('club.listMember.birthday')"
                  sortField="birthday" sortable :headerClass="(sortColumn === 'birthday' ? 'tableHeader text-primary' : 'tableHeader')" bodyClass="table-cell-link" >
            <template #body="{ data }">
              <router-link :to="{name: 'club-edit-member', query: {memberId: data.memberId, clubId: currentClubId}}" class="table-link" @click="isLoading = true" @keyup.enter="isLoading = true" @keyup.space="isLoading = true">
                {{ formatEntry('Date', data.birthday) }}
              </router-link>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('search.search')" class="form-control input-filter"/>
            </template>
          </Column>

          <Column field="emailPrivate" fieldField="emailPrivate" filterHeaderClass="table-header-filter" :showFilterMenu="false" :header="$t('club.listMember.eMailPrivate')" sortField="emailPrivate" sortable :headerClass="(sortColumn === 'emailPrivate' ? 'tableHeader text-primary' : 'tableHeader')" bodyClass="table-cell-link" >
            <template #body="{ data }">
              <router-link :to="{name: 'club-edit-member', query: {memberId: data.memberId, clubId: currentClubId}}" class="table-link" @click="isLoading = true" @keyup.enter="isLoading = true" @keyup.space="isLoading = true">
                {{ formatEntry('String', data.emailPrivate) }}
              </router-link>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('search.search')" class="form-control input-filter"/>
            </template>
          </Column>

          <Column field="emailAlternative" fieldField="emailAlternative" filterHeaderClass="table-header-filter" :showFilterMenu="false" :header="$t('club.listMember.eMailAlternative')" sortField="emailAlternative" sortable :headerClass="(sortColumn === 'emailAlternative' ? 'tableHeader text-primary' : 'tableHeader')" bodyClass="table-cell-link" >
            <template #body="{ data }">
              <router-link :to="{name: 'club-edit-member', query: {memberId: data.memberId, clubId: currentClubId}}" class="table-link" @click="isLoading = true" @keyup.enter="isLoading = true" @keyup.space="isLoading = true">
                {{ formatEntry('String', data.emailAlternative) }}
              </router-link>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('search.search')" class="form-control input-filter"/>
            </template>
          </Column>

          <Column field="emailVerein" fieldField="emailVerein" filterHeaderClass="table-header-filter" :showFilterMenu="false" :header="$t('club.listMember.eMailVerein')" sortField="emailVerein" sortable :headerClass="(sortColumn === 'emailVerein' ? 'tableHeader text-primary' : 'tableHeader')" bodyClass="table-cell-link" >
            <template #body="{ data }">
              <router-link :to="{name: 'club-edit-member', query: {memberId: data.memberId, clubId: currentClubId}}" class="table-link" @click="isLoading = true" @keyup.enter="isLoading = true" @keyup.space="isLoading = true">
                {{ formatEntry('String', data.emailVerein) }}
              </router-link>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('search.search')" class="form-control input-filter"/>
            </template>
          </Column>

          <Column field="emailVerband" fieldField="emailVerband" filterHeaderClass="table-header-filter" :showFilterMenu="false" :header="$t('club.listMember.eMailVerband')" sortField="emailVerband" sortable :headerClass="(sortColumn === 'emailVerband' ? 'tableHeader text-primary' : 'tableHeader')" bodyClass="table-cell-link" >
            <template #body="{ data }">
              <router-link :to="{name: 'club-edit-member', query: {memberId: data.memberId, clubId: currentClubId}}" class="table-link" @click="isLoading = true" @keyup.enter="isLoading = true" @keyup.space="isLoading = true">
                {{ formatEntry('String', data.emailVerband) }}
              </router-link>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('search.search')" class="form-control input-filter"/>
            </template>
          </Column>

          <Column field="phonePrivate" fieldField="phonePrivate" filterHeaderClass="table-header-filter" :showFilterMenu="false" :header="$t('club.listMember.phonePrivate')" sortField="phonePrivate" sortable :headerClass="(sortColumn === 'phonePrivate' ? 'tableHeader text-primary' : 'tableHeader')" bodyClass="table-cell-link" >
            <template #body="{ data }">
              <router-link :to="{name: 'club-edit-member', query: {memberId: data.memberId, clubId: currentClubId}}" class="table-link" @click="isLoading = true" @keyup.enter="isLoading = true" @keyup.space="isLoading = true">
                {{ formatEntry('String', data.phonePrivate) }}
              </router-link>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('search.search')" class="form-control input-filter"/>
            </template>
          </Column>

          <Column field="phoneBusiness" fieldField="phoneBusiness" filterHeaderClass="table-header-filter" :showFilterMenu="false" :header="$t('club.listMember.phoneBusiness')" sortField="phoneBusiness" sortable :headerClass="(sortColumn === 'phoneBusiness' ? 'tableHeader text-primary' : 'tableHeader')" bodyClass="table-cell-link" >
            <template #body="{ data }">
              <router-link :to="{name: 'club-edit-member', query: {memberId: data.memberId, clubId: currentClubId}}" class="table-link" @click="isLoading = true" @keyup.enter="isLoading = true" @keyup.space="isLoading = true">
                {{ formatEntry('String', data.phoneBusiness) }}
              </router-link>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('search.search')" class="form-control input-filter"/>
            </template>
          </Column>

          <Column field="phoneMobile" fieldField="phoneMobile" filterHeaderClass="table-header-filter" :showFilterMenu="false" :header="$t('club.listMember.phoneMobile')" sortField="phoneMobile" sortable :headerClass="(sortColumn === 'phoneMobile' ? 'tableHeader text-primary' : 'tableHeader')" bodyClass="table-cell-link" >
            <template #body="{ data }">
              <router-link :to="{name: 'club-edit-member', query: {memberId: data.memberId, clubId: currentClubId}}" class="table-link" @click="isLoading = true" @keyup.enter="isLoading = true" @keyup.space="isLoading = true">
                {{ formatEntry('String', data.phoneMobile) }}
              </router-link>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('search.search')" class="form-control input-filter"/>
            </template>
          </Column>

          <Column field="membershipCategories" filterField="membershipCategories" :header="$t('club.listMember.membershipCategory')" sortField="membershipCategories" sortable :headerClass="(sortColumn === 'membershipCategories' ? 'tableHeader text-primary' : 'tableHeader')" filterHeaderClass="table-header-filter" bodyClass="table-cell-link" :showFilterMenu="false" >
            <template #body="{ data }">
              <router-link :to="{name: 'club-edit-member', query: {memberId: data.memberId, clubId: currentClubId}}" class="table-link" v-for="membershipCategory in data.membershipCategories.split(',')" :key="membershipCategory" @click="isLoading = true" @keyup.enter="isLoading = true" @keyup.space="isLoading = true">
                {{ formatEntry('String', membershipCategory) }}
              </router-link>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('search.search')" class="form-control input-filter"/>
            </template>
          </Column>

          <Column field="membershipAdditions" filterField="membershipAdditions" :header="$t('club.listMember.membershipCategoryAddition')" sortField="membershipAdditions" sortable :headerClass="(sortColumn === 'membershipAdditions' ? 'tableHeader text-primary' : 'tableHeader')" filterHeaderClass="table-header-filter" bodyClass="table-cell-link" :showFilterMenu="false" >
            <template #body="{ data }">
              <router-link :to="{name: 'club-edit-member', query: {memberId: data.memberId, clubId: currentClubId}}" class="table-link" v-for="membershipAddition in data.membershipAdditions.split(',')" :key="membershipAddition" @click="isLoading = true" @keyup.enter="isLoading = true" @keyup.space="isLoading = true">
                {{ formatEntry('String', membershipAddition) }}
              </router-link>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('search.search')" class="form-control input-filter"/>
            </template>
          </Column>

          <Column field="squads" filterField="squads" :header="$t('club.listMember.squads')" sortField="squads" sortable :headerClass="(sortColumn === 'squads' ? 'tableHeader text-primary' : 'tableHeader')" filterHeaderClass="table-header-filter" bodyClass="table-cell-link" :showFilterMenu="false" >
            <template #body="{ data }">
              <router-link :to="{name: 'club-edit-member', query: {memberId: data.memberId, clubId: currentClubId}}" class="table-link" v-for="squad in data.squads.split(',')" :key="squad" @click="isLoading = true" @keyup.enter="isLoading = true" @keyup.space="isLoading = true">
                {{ formatEntry('String', squad) }}
              </router-link>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('search.search')" class="form-control input-filter"/>
            </template>
          </Column>

          <Column field="statusMySTV" filterField="statusMySTV" :header="$t('club.listMember.statusMySTV')" sortField="statusMySTV" sortable
                  :headerClass="(sortColumn === 'statusMySTV' ? 'tableHeader text-primary' : 'tableHeader')" filterHeaderClass="table-header-filter" bodyClass="table-cell-link"
                  >
            <template #body="{ data }">
              <router-link :to="{name: 'club-edit-member', query: {memberId: data.memberId, clubId: currentClubId}}" class="table-link" @click="isLoading = true" @keyup.enter="isLoading = true" @keyup.space="isLoading = true">
                <i class="bi" :class="[getStatusIcon(data.statusMySTV.toLowerCase()), 'text-' + data.statusMySTV.toLowerCase()]"
                   data-bs-toggle="tooltip" :data-bs-original-title="$t('section.clubMembers.status.' + data.statusMySTV)"
                   :aria-label="$t('section.clubMembers.status.' + data.statusMySTV)" :title="$t('section.clubMembers.status.' + data.statusMySTV)"></i>
              </router-link>
            </template>
          </Column>

          <Column field="language" fieldField="language" filterHeaderClass="table-header-filter" :showFilterMenu="false" :header="$t('club.listMember.language')" sortField="language" sortable :headerClass="(sortColumn === 'language' ? 'tableHeader text-primary' : 'tableHeader')" bodyClass="table-cell-link" >
            <template #body="{ data }">
              <router-link :to="{name: 'club-edit-member', query: {memberId: data.memberId, clubId: currentClubId}}" class="table-link" @click="isLoading = true" @keyup.enter="isLoading = true" @keyup.space="isLoading = true">
                {{ formatEntry('Language', data.language) }}
              </router-link>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <Select v-model="filterModel.value" @change="filterCallback()" :options="possibleLanguages" :placeholder="$t('search.filter')" :showClear="true">
                <template #value="slotProps">
                  <div v-if="slotProps.value" class="flex items-center">
                    <div>{{ $t('languages.' + slotProps.value) }}</div>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder}}
                  </span>
                </template>
                <template #option="slotProps">
                  {{ $t('languages.' + slotProps.option) }}
                </template>
              </Select>
            </template>
          </Column>

          <Column field="socialInsuranceNr" fieldField="socialInsuranceNr" filterHeaderClass="table-header-filter" :showFilterMenu="false" :header="$t('club.listMember.socialInsuranceNr')" sortField="socialInsuranceNr" sortable :headerClass="(sortColumn === 'socialInsuranceNr' ? 'tableHeader text-primary' : 'tableHeader')" bodyClass="table-cell-link" >
            <template #body="{ data }">
              <router-link :to="{name: 'club-edit-member', query: {memberId: data.memberId, clubId: currentClubId}}" class="table-link" @click="isLoading = true" @keyup.enter="isLoading = true" @keyup.space="isLoading = true">
                {{ formatEntry('String', data.socialInsuranceNr) }}
              </router-link>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('search.search')" class="form-control input-filter"/>
            </template>
          </Column>

          <Column field="swissAthleticsNr" fieldField="swissAthleticsNr" filterHeaderClass="table-header-filter" :showFilterMenu="false" :header="$t('club.listMember.swissAthleticsNr')" sortField="swissAthleticsNr" sortable :headerClass="(sortColumn === 'swissAthleticsNr' ? 'tableHeader text-primary' : 'tableHeader')" bodyClass="table-cell-link" >
            <template #body="{ data }">
              <router-link :to="{name: 'club-edit-member', query: {memberId: data.memberId, clubId: currentClubId}}" class="table-link" @click="isLoading = true" @keyup.enter="isLoading = true" @keyup.space="isLoading = true">
                {{ formatEntry('String', data.swissAthleticsNr) }}
              </router-link>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('search.search')" class="form-control input-filter"/>
            </template>
          </Column>

          <Column field="releaseMySTV" fieldField="releaseMySTV" filterHeaderClass="table-header-filter" :showFilterMenu="false" :header="$t('club.listMember.releaseMySTV')" sortField="releaseMySTV" sortable :headerClass="(sortColumn === 'releaseMySTV' ? 'tableHeader text-primary' : 'tableHeader')" bodyClass="table-cell-link" >
            <template #body="{ data }">
              <router-link :to="{name: 'club-edit-member', query: {memberId: data.memberId, clubId: currentClubId}}" class="table-link" @click="isLoading = true" @keyup.enter="isLoading = true" @keyup.space="isLoading = true">
                {{ formatEntry('String', data.releaseMySTV) }}
              </router-link>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('search.search')" class="form-control input-filter"/>
            </template>
          </Column>

          <Column field="typeOfSportLSA" fieldField="typeOfSportLSA" filterHeaderClass="table-header-filter" :showFilterMenu="false" :header="$t('club.listMember.lsaTypeOfSport')" sortField="typeOfSportLSA" sortable :headerClass="(sortColumn === 'typeOfSportLSA' ? 'tableHeader text-primary' : 'tableHeader')" bodyClass="table-cell-link" >
            <template #body="{ data }">
              <router-link :to="{name: 'club-edit-member', query: {memberId: data.memberId, clubId: currentClubId}}" class="table-link" @click="isLoading = true" @keyup.enter="isLoading = true" @keyup.space="isLoading = true">
                {{ formatEntry('String', data.typeOfSportLSA) }}
              </router-link>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('search.search')" class="form-control input-filter"/>
            </template>
          </Column>

          <Column field="yearValidLSA" fieldField="yearValidLSA" filterHeaderClass="table-header-filter" :showFilterMenu="false" :header="$t('club.listMember.lsaValidYear')" sortField="yearValidLSA" sortable :headerClass="(sortColumn === 'yearValidLSA' ? 'tableHeader text-primary' : 'tableHeader')" bodyClass="table-cell-link" >
            <template #body="{ data }">
              <router-link :to="{name: 'club-edit-member', query: {memberId: data.memberId, clubId: currentClubId}}" class="table-link" @click="isLoading = true" @keyup.enter="isLoading = true" @keyup.space="isLoading = true">
                {{ formatEntry('Date', data.yearValidLSA) }}
              </router-link>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('search.search')" class="form-control input-filter"/>
            </template>
          </Column>

          <Column field="mySTVComment"  :showFilterMenu="false" :header="$t('club.listMember.mySTVComment')" :hidden="true"  >
            <template #body="{ data }">
              {{ data.mySTVComment }}
            </template>
          </Column>

          <Column field="gymLiveAbo" fieldField="gymLiveAbo" :header="$t('club.member.subscriptions.gymLive')" :hidden="true" >
            <template #body="{ data }">
              {{ formatEntry('String', data.gymLiveAbo)}}
            </template>
          </Column>

          <Column field="regionalAbo" fieldField="regionalAbo" :header="$t('club.member.subscriptions.regional')" :hidden="true" >
            <template #body="{ data }">
              {{ formatEntry('String', data.regionalAbo)}}
            </template>
          </Column>

          <Column field="addressRelease" fieldField="addressRelease" :header="$t('club.member.subscriptions.addressRelease')" :hidden="true" >
            <template #body="{ data }">
              {{ formatEntry('String', data.addressRelease)}}
            </template>
          </Column>

          <Column field="advertising" fieldField="advertising" :header="$t('club.member.subscriptions.advertising')" :hidden="true" >
            <template #body="{ data }">
              {{ formatEntry('String', data.advertising)}}
            </template>
          </Column>

          <Column field="regionalAbo" fieldField="regionalAbo" :header="$t('club.member.subscriptions.regional')" :hidden="true" >
            <template #body="{ data }">
              {{ formatEntry('Boolean', data.regionalAbo)}}
            </template>
          </Column>

          <Column field="regionalAboNo" fieldField="regionalAboNo" :header="$t('club.member.subscriptions.regionalNo')" :hidden="true" >
            <template #body="{ data }">
              {{ formatEntry('String', data.regionalAboNo)}}
            </template>
          </Column>

        </DataTable>

        <div class="row w-100 flex-center mb-3 mt-3">
          <Button title="Export" label="Export" @click="exportCSV($event)" class="datatable-export me-1 width-auto" severity="secondary" outlined>
            <i class="bi bi-filetype-csv"></i>
          </Button>
          <Button title="Neues Mitglied hinzufügen" :label="$t('createNewMember.createNewMember')" data-bs-toggle="modal" data-bs-target="#addNewMemberModal" class="me-1 width-auto" severity="secondary" outlined >
            <i class="bi bi-person-add" ></i>
          </Button>
          <AddNewMemberModal id="addNewMemberModal" @start-loader="isLoading = true" @stop-loader="isLoading = false" :clubId="currentClubId" />
        </div>
      </div>
      <LoaderOverlay v-if="isLoading"/>

      <div class="row">
        <FaqPanel class="m-0 border-0"/>
      </div>
      <BootstrapToast/>
    </template>
    <template #content v-else>
      <NoClubFoundAlert/>
    </template>
  </club-layout>
</template>

<script setup>

import {computed, onMounted, onUnmounted, ref, watch} from 'vue'
import {useRoute} from 'vue-router'
import {Tooltip} from "bootstrap";
import {FilterMatchMode} from "@primevue/core/api"
import {useMemberStore} from "@/store/modules/member/member.js";
import {useClubStore} from "@/store/modules/club/club.js";
import {useI18n} from "vue-i18n";

import FaqPanel from "@/components/ui/FaqPanel.vue";
import LoaderOverlay from "@/components/ui/LoaderOverlay.vue";
import Button from 'primevue/button';
import Select from 'primevue/select';
import ClubLayout from "@/components/club/ClubLayout";

import moment from "moment/moment";

import AddNewMemberModal from '@/components/ui/AddNewMemberModal.vue';
import BootstrapToast from "@/components/ui/BootstrapToast.vue";
import NoClubFoundAlert from "@/components/ui/alert/NoClubFoundAlert.vue";

const dt = ref();
const route = useRoute()
const clubStore = useClubStore();
const memberStore = useMemberStore();
const {t} = useI18n()
const i18nLocale = useI18n().locale.value;

const clubData = computed(() => clubStore.getClubData);

const filters = ref({
  memberId: {value: null, matchMode: FilterMatchMode.CONTAINS},
  surname: {value: null, matchMode: FilterMatchMode.CONTAINS},
  firstName: {value: null, matchMode: FilterMatchMode.CONTAINS},
  socialInsuranceNr: {value: null, matchMode: FilterMatchMode.CONTAINS},
  swissAthleticsNr: {value: null, matchMode: FilterMatchMode.CONTAINS},
  salutation: {value: null, matchMode: FilterMatchMode.CONTAINS},
  language: {value: null, matchMode: FilterMatchMode.CONTAINS},
  address: {value: null, matchMode: FilterMatchMode.CONTAINS},
  postCode: {value: null, matchMode: FilterMatchMode.CONTAINS},
  city: {value: null, matchMode: FilterMatchMode.CONTAINS},
  birthday: {value: null, matchMode: FilterMatchMode.CONTAINS},
  emailPrivate: {value: null, matchMode: FilterMatchMode.CONTAINS},
  emailAlternative: {value: null, matchMode: FilterMatchMode.CONTAINS},
  emailVerein: {value: null, matchMode: FilterMatchMode.CONTAINS},
  emailVerband: {value: null, matchMode: FilterMatchMode.CONTAINS},
  phonePrivate: {value: null, matchMode: FilterMatchMode.CONTAINS},
  phoneBusiness: {value: null, matchMode: FilterMatchMode.CONTAINS},
  phoneMobile: {value: null, matchMode: FilterMatchMode.CONTAINS},
  membershipCategories: {value: null, matchMode: FilterMatchMode.CONTAINS},
  membershipAdditions: {value: null, matchMode: FilterMatchMode.CONTAINS},
  squads: {value: null, matchMode: FilterMatchMode.CONTAINS},
  statusMySTV: {value: null, matchMode: FilterMatchMode.CONTAINS},
  releaseMySTV: {value: null, matchMode: FilterMatchMode.CONTAINS},
  typeOfSportLSA: {value: null, matchMode: FilterMatchMode.CONTAINS},
  yearValidLSA: {value: null, matchMode: FilterMatchMode.CONTAINS},
});
const isSelectable = ref(false);
const isLoading = ref(false);
const sortColumn = ref('memberId');
const selection = ref([]);
const possibleSalutations = ref(["MS", "MR"]);
const possibleLanguages = ref(["de", "fr", "it", "en"])

const vereinName = computed(() => {
  let club = null;
  if (memberData.value.functions) {
    club = memberData.value.functions.find(f => {
      return !!f && f.organId === currentClubId.value;
    });
  }
  return club != null && club.organ !== null ? club.organ : '';
})

const memberData = computed(() => {
  return memberStore.getMemberData;
})

const clubMembers = computed(() => {
  return clubStore.getClubMembers;
})

const currentClubId = computed(() => {
  return clubStore.getCurrentClubId;
})

const getStatusIcon = (memberStatus) => {
  switch (memberStatus) {
    case 'initiated':
    case 'offboard':
    case 'pending':
    case 'reminded':
      return 'bi-circle-fill';
    case 'email_missing':
    case 'email_duplicate':
      return 'bi-exclamation-triangle-fill';
    case 'quarantine':
      return 'bi-wrench-adjustable-circle-fill';
    default:
      return 'bi-circle-fill';
  }
}

const onSort = (event) => {
  sortColumn.value = event.sortField;
}

const formatEntry = (type, value) => {
  switch (type) {
    case 'Date':
      return formatDate(value);
    case 'Array':
      return [ ...new Set(Array.prototype.map.call(value, s => s.description)) ].join(', ');
    case 'Salutation':
      if (value === 'MS') {
        return t('club.listMember.salutationMS');
      } else if (value === 'MR') {
        return t('club.listMember.salutationMR');
      }
      return value;
    case 'Language':
      if (value === 'de' || value === 'DE') {
        return t('languages.de');
      } else if (value === 'fr' || value === 'FR') {
        return t('languages.fr');
      }
      return value;
    case 'Boolean':
      if (value) {
        return t('yes');
      } else {
        return t('no');
      }
    default:
      return value;
  }
}

const reloadClubMembers = (clubId) => {
  if (clubId !== currentClubId.value) {
    isLoading.value = true;
    clubStore.setCurrentClubId(clubId);
    clubStore.setCurrentClubName(vereinName.value);
    clubStore.loadClubListMembers(clubId, true, true, true, i18nLocale).then(() => {
      isLoading.value = false;
    });
  }
}

const formatDate = (value, format) => {
  if (value) {
    const dateValue = moment(String(value));
    if (dateValue && dateValue.isAfter('1900-01-01')) {
      return dateValue.format(format || 'DD.MM.YYYY');
    }
  }
  return '–';
}
const exportCSV = () => {
  dt.value.exportCSV({selectionOnly: selection.value.length > 0});
}

const exportFunction = (entry) => {
  switch (entry.field) {
    case 'salutation':
      return formatEntry('Salutation', entry.data);
    case 'language':
      return formatEntry('Language', entry.data);
    case 'statusMySTV':
      return t('section.clubMembers.status.' + entry.data);
    case 'membershipCategories':
    case 'membershipAdditions':
      if(String(entry.data).endsWith(', ')) {
        return String(entry.data).slice(0, -2); //entfernt trailing ', '
      }
      return String(entry.data);
    case 'advertising':
    case 'regionalAbo':
    case 'gymLiveAbo':
    case 'addressRelease':
      return formatEntry('Boolean', entry.data);
    default:
      return String(entry.data)
  }
}

watch(
  () => route.query.clubId,
  async newClubId => {
    reloadClubMembers(newClubId)
  }, {immediate: true}
)

onMounted(() => {
  isSelectable.value = selection.value.length > 0;
  new Tooltip(document.body, {
    selector: "[data-bs-toggle='tooltip']",
  })
})

onUnmounted(() => {
  isLoading.value = false;
})
</script>
