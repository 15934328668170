<template>
  <club-layout :title="clubData.status === 200 ? $t('club.profile') + ' ' + clubData.data.Name : $t('club.notFound.title')" :is-loading="false">
    <template #content v-if="clubData.status === 200">

      <div class="col-12">
        <DataTable :value="clubSponsorsAndPartners"
          :sortField="sortColumn" :sortOrder="1" @sort="onSort"
          v-model:selection="selection"
          dataKey="memberId"
          v-model:filters="filters" filterDisplay="row"
          :loading="isLoading"
          ref="dt"
          paginator :rows="50" :rowsPerPageOptions="[50, 100, 150, 200]"
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
          :currentPageReportTemplate="`{first}` + ' ' + $t('section.clubMembers.pagination.to') + ' ' + `{last}` + ' ' + $t('section.clubMembers.pagination.of') + ' ' +  `{totalRecords}`"
          csvSeparator=";" :exportFilename="$t('section.clubMembers.exportFilenameSponsors')" :export-function="exportFunction"
          scrollable scrollHeight="35rem">

          <template #empty>{{ $t('section.clubMembers.noSponsorsFound') }}</template>
          <template #paginatorfirstpagelinkicon>
            <i class="bi bi-arrow-bar-left" />
          </template>
          <template #paginatorprevpagelinkicon>
            <i class="bi bi-arrow-left" />
          </template>
          <template #paginatornextpagelinkicon>
            <i class="bi bi-arrow-right" />
          </template>
          <template #paginatorlastpagelinkicon>
            <i class="bi bi-arrow-bar-right" />
          </template>

          <Column selectionMode="multiple" class="dt-col-2"></Column>

          <Column v-for="col in columns" :key="col.field" :field="col.field"
                  :filter-field="col.field" filter-header-class="table-header-filter" :show-filter-menu="false"
                  :header="col.header"
                  :sortable="col.sortable" :header-class="(sortColumn === col.field ? 'tableHeader text-primary' : 'tableHeader')"
                  :exportable="col.exportable"
                  :hidden="!col.visible">
            <template #body="{data}">
              <router-link :to="{name: 'club-edit-sponsor', query: {memberId: data.memberId, clubId: currentClubId}}" class="table-link">
                {{ formatEntry(col.type, data[col.field]) }}
              </router-link>
            </template>
            <template #filter="{ filterModel, filterCallback }" v-if="col.filterable">
              <InputText v-model="filterModel.value" type="text" @input="filterCallback()" :placeholder="$t('table.filter.header', {filter: col.header})" class="form-control input-filter"/>
            </template>
          </Column>
        </DataTable>

        <div class="row w-100 flex-center mb-3 mt-3">
          <Button title="Export" label="Export" @click="exportCSV($event)" class="datatable-export me-1 width-auto" severity="secondary" outlined>
            <i class="bi bi-filetype-csv"></i>
          </Button>
          <Button title="New" label="New" class="me-1 width-auto" severity="primary" outlined @click="newContact" onkeypress="newContact">
            <i class="bi bi-person-plus"></i>
          </Button>
        </div>
      </div>
      <LoaderOverlay v-if="isLoading"/>

      <div class="row">
        <FaqPanel class="m-0 border-0"/>
      </div>
      <BootstrapToast />
    </template>
    <template #content v-else>
      <NoClubFoundAlert/>
    </template>
  </club-layout>
</template>

<script setup>

import {computed, onMounted, ref, watch} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {Tooltip} from "bootstrap";
import {FilterMatchMode} from "@primevue/core/api"
import {useMemberStore} from "@/store/modules/member/member.js";
import {useClubStore} from "@/store/modules/club/club.js";
import {useI18n} from "vue-i18n";

import FaqPanel from "@/components/ui/FaqPanel.vue";
import LoaderOverlay from "@/components/ui/LoaderOverlay.vue";
import Button from 'primevue/button';
import ClubLayout from "@/components/club/ClubLayout";

import moment from "moment/moment";
import NoClubFoundAlert from "@/components/ui/alert/NoClubFoundAlert.vue";


const {t} = useI18n()
const dt = ref();
const route = useRoute()
const router = useRouter()
const clubStore = useClubStore();
const memberStore = useMemberStore();
const i18nLocale = useI18n().locale.value;

const clubData = computed(() => clubStore.getClubData);

const columns = [
  {field: 'memberId', header: t('club.listMember.nr'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'name', header: t('club.listSponsor.name'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'name2', header: t('club.listSponsor.name2'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'addition', header: t('club.member.addressAddition'), filterable: false, sortable: false, exportable: true, visible: false, type: 'String'},
  {field: 'addition2', header: t('club.member.addressAddition2'), filterable: false, sortable: false, exportable: true, visible: false, type: 'String'},
  {field: 'address', header: t('club.listMember.address'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'postCode', header: t('club.listMember.zipCode'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'city', header: t('club.listMember.place'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'country', header: t('club.member.country'), filterable: false, sortable: false, exportable: true, visible: false, type: 'String'},
  {field: 'emailPrivate', header: t('club.member.emailPrivate'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'emailAlternative', header: t('club.member.emailAlternative'), filterable: true, sortable: true, exportable: true, visible: true, type: 'String'},
  {field: 'phoneNoPrivate', header: t('club.listMember.phonePrivate'), filterable: false, sortable: false, exportable: true, visible: false, type: 'String'},
  {field: 'phoneNoMobile', header: t('club.listMember.phoneMobile'), filterable: false, sortable: false, exportable: true, visible: false, type: 'String'},
  {field: 'regionalAbo', header: t('club.member.subscriptions.regional'), filterable: true, sortable: true, exportable: true, visible: false, type: 'Boolean'},
  {field: 'regionalAbo', header: t('club.member.subscriptions.regional'), filterable: true, sortable: true, exportable: true, visible: false, type: 'Boolean'},
  {field: 'regionalAboNo', header: t('club.member.subscriptions.regionalNo'), filterable: true, sortable: true, exportable: true, visible: false, type: 'String'}
];

// setzt filter dynamisch basierend auf columns
const filters = ref( {});
for (let col in columns) {
  filters.value[columns[col].field] = {value: null, matchMode: FilterMatchMode.CONTAINS};
}

const isSelectable = ref(false);
const isLoading = ref(false);
const sortColumn = ref('memberId');
const selection = ref([]);

const vereinName = computed(() => {
  let club = null;
  if (memberData.value.functions) {
    club = memberData.value.functions.find(f => {
      return !!f && f.organId === currentClubId.value;
    });
  }
  return club != null && club.organ !== null ? club.organ : '';
})

const memberData = computed(() => {
  return memberStore.getMemberData;
})

const clubSponsorsAndPartners = computed(() => {
  return clubStore.getClubSponsorsAndPartners
})

const currentClubId = computed(() => {
  return clubStore.getCurrentClubId;
})

const onSort = (event) => {
  sortColumn.value = event.sortField;
}

const formatEntry = (type, value) => {
  switch (type) {
    case 'Date':
      return formatDate(value);
    case 'Array':
      return [ ...new Set(Array.prototype.map.call(value, s => s.description)) ].join(', ');
    case 'Boolean':
      if(value) {
        return t('yes');
      } else {
        return t('no');
      }
    default:
      return value;
  }
}

const reloadClubSponsorsAndPartners = (clubId) => {
  if (clubId !== currentClubId.value) {
    isLoading.value = true;
    clubStore.setCurrentClubId(clubId);
    clubStore.setCurrentClubName(vereinName.value);
    clubStore.loadClubListSponsorsAndPartners(clubId, false, true, true, i18nLocale).then(() => {
      isLoading.value = false;
    });
  }
}

const formatDate = (value, format) => {
  if (value) {
    const dateValue = moment(String(value));
    if (dateValue && dateValue.isAfter('1900-01-01')) {
      return dateValue.format(format || 'DD.MM.YYYY');
    }
  }
  return '–';
}
const exportCSV = () => {
  dt.value.exportCSV({selectionOnly:selection.value.length>0});
}

const exportFunction = (entry) => {
  switch (entry.field) {
    case 'advertising':
    case 'regionalAbo':
    case 'gymLiveAbo':
    case 'addressRelease':
      return formatEntry('Boolean', entry.data);
    default:
      return String(entry.data)
  }
}

const newContact = () => {
  isLoading.value = true;
  router.push({
          name: 'club-edit-sponsor',
          query: {clubId: clubStore.getCurrentClubNo, memberId: 'new'}
        });
}

watch(
  () => route.query.clubId,
  async newClubId => {
    reloadClubSponsorsAndPartners(newClubId)
  }, { immediate: true }
)

onMounted(() => {
  isSelectable.value = selection.value.length > 0;
  new Tooltip(document.body, {
    selector: "[data-bs-toggle='tooltip']",
  })
})
</script>
