<template>
  <association-layout :title="clubData?.status === 200 ? clubData.data.Name + ' / ' + $t('breadcrumb.club.sync') : $t('club.notFound.title')" :is-loading="false">
    <template #content v-if="clubData?.status === 200">

      <SyncProviderChanger :info-text="$t('association.sync.information')"></SyncProviderChanger>

    </template>

    <template #content v-else>
      <NoClubFoundAlert/>
    </template>
  </association-layout>
</template>

<script setup>
import {computed, onBeforeMount} from "vue";
import {useClubStore} from "@/store/modules/club/club";
import {useContactStore} from "@/store/modules/contact/contact";
import NoClubFoundAlert from "@/components/ui/alert/NoClubFoundAlert.vue";
import SyncProviderChanger from "@/components/SyncProviderChanger.vue";
import AssociationLayout from "@/components/association/AssociationLayout.vue";

const clubStore = useClubStore();
const contactStore = useContactStore();

const clubData = computed(() => clubStore.getClubData);

onBeforeMount(async () => {
  await contactStore.loadClubInterfaceProviders();
});

</script>

